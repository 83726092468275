import React from "react";
import "./Template.css";

export default function TemplateAnketa(data) {
  // console.log('TemplateAnketa',data)

  // console.log('TEST', UsersFor(data?.users))
  return (
    "<html>\n" +
    "<head>\n" +
    '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>\n' +
    '    <meta http-equiv="Content-Style-Type" content="text/css"/>\n' +
    '    <meta name="generator" content="Aspose.Words for .NET 21.12.0"/>\n' +
    "    <title></title>\n" +
    '    <style type="text/css">.body2 {\n' +
    "        font-family: Calibri;\n" +
    "        font-size: 10pt\n" +
    "    }\n" +
    "\n " +
    "    h1, h2, h3, h4, h5, h6, p {\n" +
    "        margin: 0pt\n" +
    "    }\n" +
    "</style>\n" +
    "</head>\n" +
    "<body>\n" +
    "<div>\n" +
    '    <p style="text-align:center; line-height:108%; border-bottom:0.75pt solid #000000; padding-bottom:1pt; margin-bottom:8pt; font-size:12pt">\n' +
    "        <span style=\"font-family:'Times New Roman'; font-weight:bold; text-transform:uppercase\">«Первый профессиональный институт эстетики»</span>\n" +
    "    </p>\n" +
    '    <p style="text-align:center; line-height:108%; font-size:12pt; margin-bottom:8pt;"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold; text-transform:uppercase\">АНКЕТА</span><br/><span\n" +
    "            style=\"font-family:'Times New Roman'; font-weight:bold\">слушателя образовательных программ</span></p>\n" +
    '    <table cellspacing="0" cellpadding="0" class="a5"\n' +
    '           style="width:525pt; border:0.75pt solid #000000; -aw-border:0.5pt single; -aw-border-insideh:0.5pt single #000000; -aw-border-insidev:0.5pt single #000000; border-collapse:collapse; margin: auto;">\n' +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single">\n' +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'">Имя</span></p></td>\n' +
    '            <td style="width:260.85pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single">\n' +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'">' +
    data.name +
    "</span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'">Фамилия</span></p></td>\n' +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'">' +
    data.surname +
    "</span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'">Отчество</span></p></td>\n' +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'">' +
    data.patronymic +
    "</span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'">Дата рождения</span></p>\n' +
    "            </td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p style=\"background-color:#ffffff\"><span style=\"font-family:'Times New Roman'\">444</span></p></td>\n" +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'"></span></p></td>\n' +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'">Контактная информация (тел, эл почта)</span>\n' +
    "                </p></td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p style=\"background-color:#ffffff\"><span style=\"font-family:'Times New Roman'\">555</span></p></td>\n" +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'"></span></p></td>\n' +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'">Сведения об образовании (законченном)</span><br/><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-size:11pt\">1. (полное среднее/среднее профессиональное / высшее; название учебного заведения; специальность / направление подготовки, квалификация); указать, если слушатель в настоящий момент продолжает образование в ординатуре</span>\n" +
    "                </p>\n" +
    '                <p style="font-size:11pt; background-color:#ffffff"><span style="font-family:\'Times New Roman\'">2. Регистрационный номер диплома/сертификата специалиста</span>\n' +
    "                </p>\n" +
    '                <p style="font-size:11pt; background-color:#ffffff"><span style="font-family:\'Times New Roman\'">3. Дата выдачи документа об образовании</span>\n' +
    "                </p></td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">666</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    '                <p style="background-color:#ffffff"><span style="font-family:\'Times New Roman\'">СНИЛС</span></p></td>\n' +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">777</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    "                <p><span style=\"font-family:'Times New Roman'\">Паспорт </span></p>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\">1. Серия</span></p>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\">2. Номер</span></p>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\">3. Дата выдачи</span></p>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\">4. Кем выдан</span></p>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\">5. Место прописки/регистрации</span></p></td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">888</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    "                <p><span style=\"font-family:'Times New Roman'\">Гражданство (если РФ, то указать код 643, если иное, то указать страну)</span>\n" +
    "                </p></td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">999</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    "                <p><span style=\"font-family:'Times New Roman'\">Документ о смене фамилии/имени</span></p></td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">121212</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    "                <p><span style=\"font-family:'Times New Roman'\">Место работы/ стаж</span></p></td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">131313</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    "    </table>\n" +
    '    <p style="margin-top:6pt; font-size:12pt; background-color:#ffffff"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold\">Информация об образовательной программе</span></p>\n" +
    '    <table cellspacing="0" cellpadding="0" class="a6"\n' +
    '          style="width:525pt; border:0.75pt solid #000000; -aw-border:0.5pt single; -aw-border-insideh:0.5pt single #000000; -aw-border-insidev:0.5pt single #000000; border-collapse:collapse; margin: auto;">\n' +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single">\n' +
    "                <p><span style=\"font-family:'Times New Roman'\">Наименование программы</span></p></td>\n" +
    '            <td style="width:260.85pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">141414</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    "                <p><span style=\"font-family:'Times New Roman'\">Продолжительность программы (ак.ч.)</span></p></td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">151515</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    "                <p><span style=\"font-family:'Times New Roman'\">Тип программы </span><br/><span\n" +
    "                        style=\"font-family:'Times New Roman'; font-size:10.5pt\">(программа повышения квалификации / программа профессиональной переподготовки / программа профессионального обучения</span>\n" +
    "                </p></td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">161616</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    "                <p><span style=\"font-family:'Times New Roman'\">Форма обучения </span><br/><span\n" +
    "                        style=\"font-family:'Times New Roman'; font-size:11pt\">(очная / очно-заочная / заочная)</span>\n" +
    "                </p></td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">171717</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    "                <p><span style=\"font-family:'Times New Roman'\">Срок обучения </span><br/><span\n" +
    "                        style=\"font-family:'Times New Roman'; font-size:11pt\">(дата начала – дата окончания)</span></p>\n" +
    "            </td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">181818</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:1pt">\n' +
    '            <td style="width:246.4pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
    "                <p><span style=\"font-family:'Times New Roman'\">Номер и дата договора на обучение</span></p></td>\n" +
    '            <td style="width:260.85pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:3.88pt; padding-left:3.88pt; vertical-align:middle; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
    // "                <p><span style=\"font-family:'Times New Roman'\">191919</span></p></td>\n" +
    "                <p><span style=\"font-family:'Times New Roman'\"></span></p></td>\n" +
    "        </tr>\n" +
    "    </table>\n" +
    '    <table cellspacing="0" cellpadding="0" class="TableGrid" style="width:525pt; border-collapse:collapse">\n' +
    "        <tr>\n" +
    '            <td style="width:14.45pt; vertical-align:top"><p style="text-align:justify; line-height:115%"><span\n' +
    '                    style="font-family:\'Times New Roman\'">Я</span><span style="font-size:10pt">,</span></p></td>\n' +
    '            <td style="width:503.2pt; vertical-align:top"><p\n' +
    '                    style="text-align:center; line-height:115%; border-bottom-style:solid; border-bottom-width:0.75pt; padding-bottom:1pt">\n' +
    "                <span style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="text-align:center; line-height:115%; font-size:8pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-style:italic\">ФИО слушателя</span></p></td>\n" +
    '            <td style="width:28.35pt; vertical-align:top"><p style="text-align:justify; line-height:115%"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">,</span></p></td>\n" +
    "        </tr>\n" +
    "    </table>\n" +
    '    <p style="text-align:justify; font-size:12pt"><span style="font-family:\'Times New Roman\'">заполняя и подписывая эту анкету, даю согласие на обработку моих персональных данных, включая сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, распространение (в том числе передачу), обезличивание, блокирование и уничтожение моих персональных данных, в порядке, установленном Федеральным законом от 27 июля 2006 г. №152-ФЗ «О персональных данных».</span>\n' +
    "    </p>\n" +
    '    <table cellspacing="0" cellpadding="0" class="TableGrid" style="width:523.55pt; border-collapse:collapse">\n' +
    "        <tr>\n" +
    '            <td style="width:163.4pt; vertical-align:top"><p\n' +
    '                    style="text-align:center; line-height:115%; border-bottom-style:solid; border-bottom-width:0.75pt; padding-bottom:1pt; font-size:10pt">\n' +
    '                <span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p style="text-align:center; line-height:115%; font-size:8pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-style:italic\">Дата</span></p></td>\n" +
    '            <td style="width:173.85pt; vertical-align:top"><p\n' +
    '                    style="text-align:justify; line-height:115%; font-size:10pt"><span\n' +
    '                    style="-aw-import:ignore">&#xa0;</span></p></td>\n' +
    '            <td style="width:186.3pt; vertical-align:top"><p\n' +
    '                    style="text-align:center; line-height:115%; border-bottom-style:solid; border-bottom-width:0.75pt; padding-bottom:1pt">\n' +
    "                <span style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="text-align:center; line-height:115%; font-size:8pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-style:italic\">Подпись</span></p></td>\n" +
    "        </tr>\n" +
    "    </table>\n" +
    '    <p style="text-align:justify; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></div>\n" +
    "</body>\n" +
    "</html>"
  );
}
