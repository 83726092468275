import React from "react";
import {Col, Form, Placeholder, Row} from "react-bootstrap";

export default function PlaceholderLoadingForm() {
  return (
    <>
      <Placeholder as={"h2"} animation="glow">
        <Placeholder xs={6} />
      </Placeholder>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="title">
            <Placeholder as={"p"} className={"mb-1"} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={"h2"} animation="glow">
              <Placeholder xs={12} size="lg" />
            </Placeholder>
          </Form.Group>

          <Form.Group className="mb-3" controlId="titlePrint">
            <Placeholder as={"p"} className={"mb-1"} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={"h2"} animation="glow">
              <Placeholder xs={12} size="lg" />
            </Placeholder>
          </Form.Group>

          <Form.Group className="mb-3" controlId="type">
            <Placeholder as={"p"} className={"mb-1"} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={"h2"} animation="glow">
              <Placeholder xs={12} size="lg" />
            </Placeholder>
          </Form.Group>

          <Form.Group className="mb-3" controlId="description">
            <Placeholder as={"p"} className={"mb-1"} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={"h2"} animation="glow">
              <Placeholder xs={12} size="lg" />
              <Placeholder xs={9} />
              <Placeholder xs={7} />
            </Placeholder>
          </Form.Group>

          <Placeholder.Button variant="success" xs={4} />
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="titlePrint">
            <Placeholder as={"p"} className={"mb-1"} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={"h2"} animation="glow">
              <Placeholder xs={12} size="lg" />
            </Placeholder>
          </Form.Group>

          <Form.Group className="mb-3" controlId="titlePrint">
            <Placeholder as={"p"} className={"mb-1"} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={"h2"} animation="glow">
              <Placeholder xs={12} size="lg" />
            </Placeholder>
          </Form.Group>

          <Form.Group className="mb-3" controlId="titlePrint">
            <Placeholder.Button variant="danger" xs={12} />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
