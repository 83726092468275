import React, {useEffect, useState} from "react";
import Loading from "../../../components/Loading";
import CONSTANTS from "../../../Constants";
import useMasterGetFetch from "../../../components/fetch/useMasterGetFetch";
import NodeMap from "../group/docs/NodeMap";

export default function ArchiveTablePage() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const fetchGet = useMasterGetFetch({
    name: "Group",
    url: CONSTANTS.URL.GET_GROUPS_AND_USERS,
    id: 6,
    page,
    perPage,
  });

  const data = React.useMemo(() => fetchGet?.data?.data, [fetchGet?.data]);
  const [groupData, setGroupData] = useState(data);

  useEffect(() => {
    if (fetchGet?.data?.data) {
      setGroupData(data[0]);
    }
  }, [fetchGet?.data]);

  function ComplexOrNot({groupData, setGroupData}) {
    // console.log('ComplexOrNot', groupData)
    if (!groupData?.students?.length) {
      return "Чёт не вижу студентов, всё хорошо?";
    }
    if (groupData?.course?.complex?.length) {
      return groupData?.course?.complex?.map((course, courseIndex) => (
        <NodeMap
          groupData={groupData}
          setGroupData={setGroupData}
          isComplex={true}
          courseIndex={courseIndex}
        />
      ));
    } else {
      return <NodeMap groupData={groupData} setGroupData={setGroupData} />;
    }
  }

  if (fetchGet?.error) {
    return (
      <>
        Ошибка
        <pre>{fetchGet?.error?.message}</pre>
      </>
    );
  } else if (fetchGet?.isLoading) {
    return (
      <div className={"text-center"}>
        <Loading />
      </div>
    );
  } else if (!data?.length) {
    return <>Нет данных, может ошибка.</>;
  } else {
    return (
      <>
        <h2>
          Архив документов <Loading conditional={fetchGet?.isRefetching} />
        </h2>
        <p>
          {" "}
          Тут будет архив.
          <br /> Все документы с приставленными номерами и данные всей группы будут
          отображены здесь.
          <br /> Чтобы добавить документ, надо зайти в активную группу и нажать
          сформировать документы, после чего можно будет посмотреть и напечатать документы
          здесь.
        </p>
        {/*<PrintAll Template={[TemplateSvid, TemplateSvidPlus]} templateData={groupData}/>*/}

        <ComplexOrNot groupData={groupData} setGroupData={setGroupData} />

        <pre>
          <code>{JSON.stringify(groupData, null, 2)}</code>
        </pre>
      </>
    );
  }
}
