import Config from "./Config";
import {useDispatch} from "react-redux";
import {logout} from "../../pages/auth/components/authSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import GeneratorURL from "./GeneratorURL";

const {useQuery} = require("react-query");
const axios = require("axios");

export default function useMasterGetFetch({
  name,
  urlEntity,
  itemId,
  page,
  perPage,
  fetchOptions,
  addCenter,
  centerId,
}) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  let {from} = location.state || {from: {pathname: "/login"}};
  const config = Config();
  const finalURL = GeneratorURL({urlEntity, itemId, page, perPage, addCenter, centerId});

  const query = useQuery(
    [name, page, perPage],
    async () => {
      const res = await axios.get(finalURL, config);

      return res.data;
    },
    {
      ...fetchOptions,
      retry: false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );
  useEffect(() => {
    let refetch = query.refetch(itemId);
  }, [itemId]);
  useEffect(() => {
    if (query.error?.response.status === 401) {
      localStorage.removeItem("user");
      dispatch(logout());
      navigate(from);
    }
  }, [query]);

  return query;
}
