import React, {useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {PrintAll} from "../../components/utils/PrintAll";
import {PrintElem} from "../../components/utils/PrintElem";
import TemplateVeditog from "../../components/templates/TemplateVeditog";
import FormVeditog from "./FormVeditog";
// import './docGen.css';

export default function GenVeditog({defaultData, title}) {
  // console.log('GenVeditog defaultData', defaultData)
  // console.log('GenVeditog title', title)
  // document.title = title

  const [templateData, setTemplateData] = useState(
    defaultData?.length
      ? [...defaultData]
      : [
          {
            num1: "",
            num2: "",
            course: "Курс",
            date: "2021-11-11",
            namePredsedatel: "Имя 1",
            name_Chel_com: "Имя 2",
            nameSecretar: "Имя 3",
            users: [{name: "Имя 4", testPr: "", testBall: "", sobBilet: "", sobBall: ""}],
          },
        ],
  );

  // console.log('GenVeditog templateData', templateData)

  return (
    <Row>
      <Col md={"5"}>
        <h2>Вед. Итог. Аттестация</h2>
      </Col>
      <Col md={"7"}>
        <PrintAll Template={[TemplateVeditog]} templateData={templateData} />
      </Col>
      <hr />
      {templateData?.map((val, index) => {
        // console.log("templateData val", val)
        return (
          <Row key={index}>
            <FormVeditog
              index={index}
              templateData={templateData}
              setTemplateData={setTemplateData}
              defaultData={val}
            />
            <Col md={"7"} key={index}>
              <h3>
                Пример{" "}
                <Button onClick={() => PrintElem("contentVeditog" + index)} size={"sm"}>
                  Печать
                </Button>
              </h3>
              <div id={"contentVeditog" + index} className={"PrintPreview"}>
                <div
                  className="wrapperPrint body2"
                  dangerouslySetInnerHTML={{__html: TemplateVeditog(val)}}
                />
              </div>
            </Col>
            <hr />
          </Row>
        );
      })}
    </Row>
  );
}
