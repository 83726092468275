import React, {useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {PrintElem} from "../../components/utils/PrintElem";
import TemplateDiploma from "../../components/templates/TemplateDiploma";
import FormDiploma from "./FormDiploma";
import FormDiplomaPlus from "./FormDiplomaPlus";
import TemplateDiplomaPlus from "../../components/templates/TemplateDiplomaPlus";
import TemplateDiplomaPlus2 from "../../components/templates/TemplateDiplomaPlus2";
import FormDiplomaPlus2 from "./FormDiplomaPlus2";
// import './docGen.css';

export default function GenDiploma({defaultData}) {
  // console.log('GenProtokol defaultData', defaultData)

  const [templateData, setTemplateData] = useState(
    defaultData?.length
      ? [...defaultData]
      : [
          {
            regNum: "123132",
            course: {
              title: "Курс",
              hours: 123,
            },
            hours: 1211,
            spec: "uuusususu",
            additional: "Доп сведенья",
            dateAccept: "2022-10-10",
            dateSend: "2022-10-10",
            date_from: "2021-11-11",
            date_to: "2021-12-11",
            namePredsedatel: "Имя 1",
            name_Chel_com: "Имя 2",
            nameSecretar: "Имя 3",
            name_Supervisor: "444Bahringer Alford Lindgren Rau",
            students: {
              name: "Имя 4",
              fullName: "Имя Фамилия и чай",
              pastEduction: "Диплом о среднем профессиональном образовании 2017 год.",
              testPr: "",
              testBall: "",
              sobBilet: "",
              sobBall: "",
            },
            disciplines: [
              {
                id: 9,
                title: "Alford Lindgren",
                hours: 100,
                score: 5,
              },
              {
                id: 9,
                title: "Alford Lindgren",
                hours: 100,
                score: 5,
              },
            ],
            staff: [
              {
                id: 9,
                name: "Alford Lindgren",
                surname: "Bahringer",
                patronymic: "Rau",
                created_at: "2022-01-10T09:02:49.000000Z",
                updated_at: "2022-01-10T09:02:49.000000Z",
                fullName: "111Bahringer Alford Lindgren Rau",
                pivot: {
                  group_id: 3,
                  staff_id: 9,
                  type: 1,
                },
              },
              {
                id: 9,
                name: "222Alford Lindgren",
                surname: "Bahringer",
                patronymic: "Rau",
                created_at: "2022-01-10T09:02:49.000000Z",
                updated_at: "2022-01-10T09:02:49.000000Z",
                fullName: "222Bahringer Alford Lindgren Rau",
                pivot: {
                  group_id: 3,
                  staff_id: 9,
                  type: 2,
                },
              },
              {
                id: 9,
                name: "333Alford Lindgren",
                surname: "Bahringer",
                patronymic: "Rau",
                created_at: "2022-01-10T09:02:49.000000Z",
                updated_at: "2022-01-10T09:02:49.000000Z",
                fullName: "333Bahringer Alford Lindgren Rau",
                pivot: {
                  group_id: 3,
                  staff_id: 9,
                  type: 3,
                },
              },
              {
                id: 9,
                name: "4444Alford Lindgren",
                surname: "Bahringer",
                patronymic: "Rau",
                created_at: "2022-01-10T09:02:49.000000Z",
                updated_at: "2022-01-10T09:02:49.000000Z",
                fullName: "444Bahringer Alford Lindgren Rau",
                pivot: {
                  group_id: 3,
                  staff_id: 9,
                  type: 4,
                },
              },
            ],
          },
        ],
  );

  // console.log('GenProtokol templateData', templateData)

  return (
    <Row>
      <Col md={"4"}>
        <h2>Диплом</h2>
      </Col>
      <Col md={"8"}>
        {/*<PrintAll Template={[TemplateDiploma,TemplateDiplomaPlus,TemplateDiplomaPlus2]} templateData={templateData}/>*/}
      </Col>
      <hr />
      {templateData?.map((val, index) => {
        // console.log("templateData val", val)
        return (
          <div key={"contentAllDiploma" + index}>
            <Row key={"contentDiploma" + index}>
              <FormDiploma
                index={index}
                templateData={templateData}
                setTemplateData={setTemplateData}
                defaultData={val}
              />
              <Col md={"8"}>
                <h3>
                  Пример{" "}
                  <Button onClick={() => PrintElem("contentDiploma" + index)} size={"sm"}>
                    Печать
                  </Button>
                </h3>
                <div id={"contentDiploma" + index} className={"PrintPreview fixDiploma"}>
                  <div
                    className="wrapperPrint body2"
                    dangerouslySetInnerHTML={{__html: TemplateDiploma(val)}}
                  />
                </div>
              </Col>
            </Row>
            <hr className={"my-2"} />
            <Row key={"contentDiplomaPlus" + index}>
              <FormDiplomaPlus
                index={index}
                templateData={templateData}
                setTemplateData={setTemplateData}
                defaultData={val}
              />
              <Col md={"7"}>
                <h3>
                  Пример{" "}
                  <Button
                    onClick={() => PrintElem("contentDiplomaPlus" + index)}
                    size={"sm"}
                  >
                    Печать
                  </Button>
                </h3>
                <div id={"contentDiplomaPlus" + index} className={"PrintPreview "}>
                  <div
                    className="wrapperPrint body2"
                    dangerouslySetInnerHTML={{__html: TemplateDiplomaPlus(val)}}
                  />
                </div>
              </Col>
            </Row>
            <hr className={"my-2"} />
            <Row key={"contentDiplomaPlus2" + index}>
              <FormDiplomaPlus2
                index={index}
                templateData={templateData}
                setTemplateData={setTemplateData}
                defaultData={val}
              />
              <Col md={"7"}>
                <h3>
                  Пример{" "}
                  <Button
                    onClick={() => PrintElem("contentDiplomaPlus2" + index)}
                    size={"sm"}
                  >
                    Печать
                  </Button>
                </h3>
                <div id={"contentDiplomaPlus2" + index} className={"PrintPreview"}>
                  <div
                    className="wrapperPrint body2"
                    dangerouslySetInnerHTML={{__html: TemplateDiplomaPlus2(val)}}
                  />
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </Row>
  );
}
