import React, {useEffect, useState} from "react";
import useMasterGetFetch from "../../../../components/fetch/useMasterGetFetch";
import CONSTANTS from "../../../../Constants";
import Loading from "../../../../components/Loading";
import {Alert, Button, Card, Row, Stack} from "react-bootstrap";
import GroupStudentsByFIO from "./GroupStudentsByFIO";
import DoubleSideLeft from "./DoubleSideLeft";
import DoubleSideRight from "./DoubleSideRight";
import useMasterPutFetch from "../../../../components/fetch/useMasterPutFetch";
import StudentPageLoading from "../StudentPageLoading";
import {Link} from "react-router-dom";
import {FaEye, FaUsers} from "react-icons/fa";

export default function DoublePage() {
  const [dataStudents, setDataStudents] = useState([]);
  const [dataStudentsGroupBy, setDataStudentsGroupBy] = useState([]);
  const [selectLeftInput, setSelectLeftInput] = useState(0);
  const [radioLeftInput, setRadioLeftInput] = useState(0);
  const [checkRightInput, setCheckRightInput] = useState([]);
  const fetchGet = useMasterGetFetch({
    name: "DoublePage",
    urlEntity: CONSTANTS.URL.GET_STUDENT,
  });

  console.log("radioLeftInput", radioLeftInput);
  const fetchPut = useMasterPutFetch({
    name: "DoublePagePut",
    urlEntity: CONSTANTS.URL.GET_STUDENT,
    itemId: radioLeftInput,
    urlItem: "marge",
    data: {DoubleStudents: checkRightInput},
  });
  console.log("fetchPut status", fetchPut);
  useEffect(() => {
    if (fetchGet?.data?.data) {
      const getStudents = fetchGet?.data?.data;
      GroupStudentsByFIO({getStudents, setDataStudents, setDataStudentsGroupBy});
    }
  }, [fetchGet?.data]);

  function InfoAlert() {
    return (
      <Alert variant={"info"} className={"my-2"}>
        Нет нужного студента в списке? Если студент не попал в этот список, значит его
        ФАМИЛИЯ и ИМЯ отличаются.
        <br />
        <b>Например</b>, была Иванова Светлана (старое имя) и Макарова Светлана (это новое
        имя). В таком случае нужно зайти в карточку студента с СТАРЫМ именем, изменить
        ФАМИЛИЮ и ИМЯ на новое, после чего они появятся в этом списке и их можно
        объединить в 1 карточку.
      </Alert>
    );
  }

  // console.log('dataStudentsGroupBy', dataStudentsGroupBy)
  if (fetchGet?.error || fetchPut?.error) {
    return (
      <>
        Ошибка
        <pre>{fetchGet?.error?.message} </pre>
        <pre>{fetchPut?.error?.message} </pre>
      </>
    );
  } else if (fetchGet?.isLoading) {
    return (
      <div className={"text-center"}>
        <Loading />
      </div>
    );
  } else if (!dataStudents?.length) {
    return <>Нет данных, может ошибка.</>;
  } else if (fetchPut.status === "success") {
    return (
      <>
        <Alert variant={"success"}>Успешно объединено!</Alert>
        <Card.Body className={"text-center"}>
          <Button as={Link} to={"/student/" + radioLeftInput} variant={"outline-primary"}>
            <FaEye /> Посмотреть карточку студента
          </Button>{" "}
          или{" "}
          <Button
            as={Link}
            to={"/student/dub"}
            variant={"outline-warning"}
            disabled={!dataStudentsGroupBy?.length}
            onClick={() => {
              setRadioLeftInput(0);
              setCheckRightInput([]);
              fetchGet.refetch();
            }}
          >
            <FaUsers /> Другое объединение (Дубликатов: {dataStudentsGroupBy?.length})
          </Button>
        </Card.Body>
        <InfoAlert />
      </>
    );
  } else {
    return (
      <>
        <Stack direction="horizontal" gap={3}>
          <div>
            <h2>
              Дубликаты студентов <Loading conditional={fetchGet?.isRefetching} />
            </h2>
          </div>
        </Stack>

        <Row>
          {fetchGet?.isRefetching ||
          fetchPut?.isRefetching ||
          fetchGet?.isLoading ||
          fetchPut?.isLoading ? (
            <StudentPageLoading />
          ) : (
            <>
              <DoubleSideLeft
                setSelectLeftInput={setSelectLeftInput}
                selectLeftInput={selectLeftInput}
                dataStudentsGroupBy={dataStudentsGroupBy}
                radioLeftInput={radioLeftInput}
                setRadioLeftInput={setRadioLeftInput}
                setCheckRightInput={setCheckRightInput}
              />
              <DoubleSideRight
                setSelectLeftInput={setSelectLeftInput}
                selectLeftInput={selectLeftInput}
                dataStudentsGroupBy={dataStudentsGroupBy}
                setRadioLeftInput={setRadioLeftInput}
                radioLeftInput={radioLeftInput}
                setCheckRightInput={setCheckRightInput}
                checkRightInput={checkRightInput}
                fetchPut={fetchPut}
              />
            </>
          )}
        </Row>
        <InfoAlert />
        <pre>{/*<code>{JSON.stringify(dataStudents, null, 2)}</code>*/}</pre>
      </>
    );
  }
}
