import React from "react";
import {Spinner} from "react-bootstrap";

export default function Loading({conditional = true, size = ""}) {
  if (conditional) {
    return (
      <Spinner animation="border" role="status" size={size}>
        <span className="visually-hidden">Загрузка...</span>
      </Spinner>
    );
  }
  return "";
}
