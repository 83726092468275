import React from "react";
import {Card} from "react-bootstrap";
import NodeSwitch from "./NodeSwitch";
import GetCOURSE_PRINT_NODE from "../../../../components/GetCOURSE_PRINT_NODE";

export default function NodeMap(props) {
  const course = props.group.course;

  if (!course.print_nodes.length) {
    return (
      <div className={"alert-danger"}>
        <h3>В курсе нет документов.</h3>
        Для курса "<a href={"/course/" + course.id}>{course.title}</a> " не выбраны
        документы, поэтому тут нельзя ничего отобразить. Открой{" "}
        <a href={"/course/" + course.id}>настройки курса</a> и выберите документы для
        студентов и общие документы курса.
      </div>
    );
  }

  return course.print_nodes.map((node, nodeIndex) => {
    return (
      <React.Fragment key={"NodeMap" + nodeIndex}>
        <Card border={node.type ? "" : ""} className={"mb-3 border-1"}>
          <Card.Header>
            {GetCOURSE_PRINT_NODE(node.node).label} | тип: {node.type + 1}
          </Card.Header>
          <Card.Body>
            <NodeSwitch node={node} props={props} />
            <div>
              {node.type ? "Документ для одного студента" : "Документ для всей группы"}
            </div>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  });
}
