import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import {NavLink} from "react-router-dom";
import {Breadcrumb, Container} from "react-bootstrap";

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <React.Fragment>
      <Container fluid id={"breadcrumb"}>
        <Breadcrumb>
          {breadcrumbs.map(({match, breadcrumb}, index) => (
            <NavLink
              as={Breadcrumb.Item}
              className={"breadcrumb-item"}
              key={"breadcrumbKey" + index}
              to={match?.pathname}
            >
              {breadcrumb}
            </NavLink>
          ))}
        </Breadcrumb>
      </Container>
    </React.Fragment>
  );
};
export {Breadcrumbs};
