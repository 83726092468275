import React from "react";
import Loading from "../components/Loading";
import {Badge, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {BsChatLeftDots, BsFillQuestionCircleFill} from "react-icons/bs";
import useMasterGetFetch from "../components/fetch/useMasterGetFetch";

export default function Footer() {
  return (
    <footer id="footer" className="mt-auto py-3 bg-light">
      <div className="container">
        <Row>
          <Col md={6}>
            <span className="text-muted">
              CDAP v{process.env.REACT_APP_VERSION} / API <GetVersion />{" "}
            </span>
          </Col>
          <Col>
            <Row>
              <Col md={"auto"}>
                <Link to={"/help"}>
                  <BsFillQuestionCircleFill /> Справка
                </Link>
              </Col>
              <Col>
                <Link to={"/support"}>
                  <BsChatLeftDots /> Поддержка
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

function GetVersion() {
  const fetchGet = useMasterGetFetch({
    name: "version",
    urlEntity: "version",
    addCenter: false,
    centerId: false,
  });

  if (fetchGet?.error) {
    console.log("SERVER: ", fetchGet?.error?.message);
    return (
      <Badge bg={"danger"}>
        Сервер недоступен
        {/*<pre>{fetchGet?.error?.message}</pre>*/}
      </Badge>
    );
  } else if (fetchGet?.isLoading) {
    return <Loading size={"sm"} />;
  } else if (!fetchGet.data.version_api) {
    console.log("SERVER data  : ", fetchGet.data);
    return <Badge bg={"warning"}>Сервер конфликт данных</Badge>;
  } else {
    return "v" + fetchGet.data.version_api;
  }
}
