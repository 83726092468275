import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Nav, Navbar, NavDropdown, NavLink} from "react-bootstrap";
import "./Header.css";
import UserBar from "./UserBar";

function Header() {
  const array = [false, false];
  const [show, setShow] = useState(array);
  const showDropdown = (e, index) => {
    const arr = show;
    arr[index] = !show[index];
    setShow([...arr]);
  };
  const hideDropdown = (e, index) => {
    const arr = show;
    arr[index] = false;
    setShow([...arr]);
  };

  return (
    <header>
      <Navbar className={"container-fluid"} variant="dark" bg="dark" expand="lg">
        <Navbar.Brand as={Link} to="/" name={"menu"}>
          CDAP
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-dark-example" />
        <Navbar.Collapse>
          <Nav>
            <NavLink as={Link} to="/list">
              Группы и слушатели
            </NavLink>
            <NavDropdown
              title="Справочник"
              menuVariant="dark"
              show={show[0]}
              onMouseEnter={(e) => showDropdown(e, 0)}
              onMouseLeave={(e) => hideDropdown(e, 0)}
              onClick={(e) => showDropdown(e, 0)}
            >
              <NavDropdown.Item as={Link} to="/group">
                Группы
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/student">
                Студенты
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/staff">
                Сотрудники
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/course">
                Курсы
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/courseCategory">
                Курсы - категории
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/discipline">
                Дисциплины
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/archive">
                Архив
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/help">
                Помощь с программой
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Ручное создание документов"
              menuVariant="dark"
              show={show[1]}
              onMouseEnter={(e) => showDropdown(e, 1)}
              onMouseLeave={(e) => hideDropdown(e, 1)}
              onClick={(e) => showDropdown(e, 1)}
            >
              <NavDropdown.Item as={Link} to="/protokol">
                Протокол
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/svid">
                Свидетельство о проф.*
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/diploma">
                Диплом о проф.*
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/veditog">
                Вед. Итог. Аттестация
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/prikaz">
                Приказ
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/anketa">
                Анкета
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.ItemText>
                <small>* надо пробросить данные с полей после проверки печати.</small>
              </NavDropdown.ItemText>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <UserBar />
      </Navbar>
    </header>
  );
}

export default Header;
