import React, {useEffect} from "react";
import {Button, Card, Col, Form, FormControl, InputGroup} from "react-bootstrap";
import {useFieldArray, useForm} from "react-hook-form";
import DateCreateFromParser from "../../components/utils/dateCreateFromParser";
import findStaff from "../../components/utils/findStaff";

export default function FormDiplomaPlus2({
  defaultData,
  index,
  templateData,
  setTemplateData,
}) {
  let newData = defaultData;

  // console.log('newData', newData)

  newData = {
    ...newData,
    nameSecretar: findStaff(newData, 3),
    name_Supervisor: findStaff(newData, 4),
  };

  findStaff(newData, 5);
  const {control, register, handleSubmit, watch} = useForm({defaultValues: newData});
  const {fields, append, remove} = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "disciplines", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const onSubmit = (data) => console.log(data);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      const NewData = value;

      NewData.date = DateCreateFromParser(NewData.date);

      const NewTemplateData = templateData;
      NewTemplateData[index] = NewData;
      setTemplateData([...NewTemplateData]);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Col md={"5"}>
        <h3>Приложение к диплому (Дисциплины)</h3>
        <Card>
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                <h4>
                  Наименование предметов:{" "}
                  <Button
                    onClick={() => append({name: ""})}
                    size={"sm"}
                    variant={"success"}
                  >
                    +
                  </Button>
                </h4>
                <div className="col-12 my-1">
                  {fields.map((field, index) => (
                    <InputGroup
                      className="input-group px-2 mb-3"
                      key={index + "boxDiploma"}
                    >
                      <InputGroup.Text>Предмет</InputGroup.Text>
                      <FormControl
                        type="text"
                        key={index + "title"} // important to include key with field's id
                        {...register(`disciplines.${index}.title`)}
                      />
                      <Button onClick={() => remove(index)} variant={"warning"}>
                        -
                      </Button>
                      <InputGroup>
                        <InputGroup.Text>Общ. кол. часов и оценка </InputGroup.Text>
                        <FormControl
                          aria-label="First name"
                          key={field.id + "testPr"}
                          {...register(`disciplines.${index}.hours`)}
                        />
                        <Form.Select
                          aria-label="Default select example"
                          {...register(`disciplines.${index}.score`)}
                        >
                          <option value="0">выберите оценку</option>
                          <option value="5">отлично</option>
                          <option value="4">хорошо</option>
                          <option value="3">удовлетворительно</option>
                        </Form.Select>
                      </InputGroup>
                    </InputGroup>
                  ))}
                </div>

                <div className="col-12 my-1">
                  <label htmlFor="additional" className="form-label">
                    Дополнительные сведенья
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      id="additional"
                      {...register("additional")}
                    />
                  </div>
                </div>

                <div className="col-12 my-1">
                  <label htmlFor="name_Supervisor" className="form-label">
                    Руководитель
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      id="name_Supervisor"
                      {...register("name_Supervisor")}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="nameSecretar" className="form-label">
                    Секретарь
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      id="nameSecretar"
                      {...register("nameSecretar")}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
