import {createSlice} from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {
      name: "",
      token: "",
    },
    isAuth: false,
  },
  reducers: {
    login: (state, action) => {
      // console.log('привет state', state.user)
      // console.log('привет action', action.payload)
      state.user = action.payload;
      state.isAuth = true;
    },
    logout: (state) => {
      state.user = {
        name: "",
        token: "",
      };
      state.isAuth = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {login, logout} = authSlice.actions;

export default authSlice.reducer;
