import React from "react";
import CONSTANTS from "../Constants";
import PrivateRoute from "../pages/auth/PrivateRoute";
import Loading from "../components/Loading";

const WrapperFetchData = React.lazy(() => import("../components/WrapperFetchData"));
const DisciplineTablePage = React.lazy(() =>
  import("../pages/entity/discipline/DisciplineTablePage"),
);
const DisciplinePage = React.lazy(() =>
  import("../pages/entity/discipline/DisciplinePage"),
);

export function RouteDisciplineId() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <WrapperFetchData url={CONSTANTS.URL.GET_DISCIPLINE}>
          <DisciplinePage />
        </WrapperFetchData>
      </React.Suspense>
    </PrivateRoute>
  );
}

export function RouteDisciplineNew() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <DisciplinePage url={CONSTANTS.URL.GET_DISCIPLINE} />
      </React.Suspense>
    </PrivateRoute>
  );
}

export function RouteDisciplineIndex() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <DisciplineTablePage />
      </React.Suspense>
    </PrivateRoute>
  );
}
