import React, {useCallback} from "react";
import {useExpanded, usePagination, useRowSelect, useTable} from "react-table";
import {ColumnsGroupsAndUsers} from "./ColumnsGroupsAndUsers.js";
import {Button, Form, FormControl, Table} from "react-bootstrap";

export default function TableGroupsAndUsers({
  data,
  setPage,
  setPerPage,
  currentpage,
  perPage,
  totalPage,
  isRefetching,
}) {
  // console.log('TableOfUsers ', users)
  const columns = React.useMemo(() => ColumnsGroupsAndUsers, []);

  // const IndeterminateCheckbox = React.forwardRef(
  //   ({indeterminate, ...rest}, ref) => {
  //     const defaultRef = React.useRef()
  //     const resolvedRef = ref || defaultRef
  //
  //     React.useEffect(() => {
  //       resolvedRef.current.indeterminate = indeterminate
  //     }, [resolvedRef, indeterminate])
  //
  //     return (
  //       <>
  //         <input type="checkbox" ref={resolvedRef} {...rest} />
  //       </>
  //     )
  //   }
  // )
  const getSubRows = useCallback((row) => {
    return row?.students || [];
  }, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    // canPreviousPage,
    // canNextPage,
    pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    selectedFlatRows,
    state: {pageIndex, pageSize, selectedRowIds, expanded},
  } = useTable(
    {
      columns: React.useMemo(() => columns, []),
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state, currentpage],
        );
      },
      initialState: {pageIndex: currentpage}, // Pass our hoisted table state
      manualPagination: true,
      pageCount: totalPage,
      getSubRows,
    },

    useExpanded,
    usePagination,
    useRowSelect,
    () => {
      // hooks.visibleColumns.push(columns => [
      //   // Let's make a column for selection
      //   {
      //     id: 'selection',
      //     // The header can use the table's getToggleAllRowsSelectedProps method
      //     // to render a checkbox
      //     Header: ({getToggleAllPageRowsSelectedProps}) => (
      //       <div>
      //         <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
      //       </div>
      //     ),
      //     // The cell can use the individual row's getToggleRowSelectedProps method
      //     // to the render a checkbox
      //     Cell: ({row}) => (
      //       <div>
      //         <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      //       </div>
      //     ),
      //   },
      //   ...columns,
      // ])
    },
  );

  function LoadingPage() {
    return page.map((row, i) => {
      prepareRow(row);
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell) => {
            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
          })}
        </tr>
      );
    });
  }

  return (
    <>
      <Table striped bordered hover {...getTableProps()} style={null}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <LoadingPage />
        </tbody>
      </Table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <Button
          className={"me-1"}
          size={"sm"}
          onClick={() => setPage(1)}
          disabled={currentpage === 1}
        >
          {"<<"}
        </Button>
        <Button
          className={"me-1"}
          size={"sm"}
          onClick={() => setPage((s) => (s === 0 ? 1 : s - 1))}
          disabled={currentpage === 1}
        >
          {"<"}
        </Button>
        <Button
          className={"me-1"}
          size={"sm"}
          onClick={() => setPage((s) => s + 1)}
          disabled={currentpage === totalPage}
        >
          {">"}
        </Button>
        <Button
          className={"me-1"}
          size={"sm"}
          onClick={() => setPage(totalPage)}
          disabled={currentpage === totalPage}
        >
          {">>"}
        </Button>
        <span style={{marginTop: "8px"}}>
          Страница{" "}
          <strong>
            {pageIndex} из {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Перейти к стр.:{" "}
          <FormControl
            className={"d-inline-block"}
            type="number"
            defaultValue={pageIndex}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) : 1;
              setPage(page);
            }}
            style={{width: "100px"}}
            min={1}
            max={totalPage}
          />
        </span>{" "}
        <Form.Select
          className="d-inline-block w-auto"
          value={perPage}
          onChange={(e) => {
            setPerPage(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Показать {pageSize}
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  );
}
