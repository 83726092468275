import React, {useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import TemplatePrikaz from "../../components/templates/TemplatePrikaz";
import FormPrikaz from "./FormPrikaz";
import {PrintAll} from "../../components/utils/PrintAll";
import {PrintElem} from "../../components/utils/PrintElem";
// import './docGen.css';

export default function GenPrikaz({defaultData, title}) {
  // console.log('GenPrikaz defaultData', defaultData)
  // console.log('GenPrikaz title', title)
  // document.title = title

  const [templateData, setTemplateData] = useState(
    defaultData?.length
      ? [...defaultData]
      : [
          {
            date: "",
            prikazNum: "",
            course: {
              title: "",
              hours: "",
            },
            date_from: "",
            date_to: "",
            students: [{fullName: ""}],
          },
        ],
  );

  // console.log('GenProtokol templateData', templateData)

  return (
    <Row>
      <Col md={"5"}>
        <h2>Приказ</h2>
      </Col>
      <Col md={"7"}>
        <PrintAll Template={[TemplatePrikaz]} templateData={templateData} />
      </Col>
      <hr />
      {templateData?.map((val, index) => {
        // console.log("templateData val", val)
        return (
          <Row key={index}>
            <FormPrikaz
              index={index}
              templateData={templateData}
              setTemplateData={setTemplateData}
              defaultData={val}
            />
            <Col md={"7"} key={index}>
              <h3>
                Пример{" "}
                <Button onClick={() => PrintElem("contentPrikaz" + index)} size={"sm"}>
                  Печать
                </Button>
              </h3>
              <div id={"contentPrikaz" + index} className={"PrintPreview"}>
                <div
                  className="wrapperPrint body2"
                  dangerouslySetInnerHTML={{__html: TemplatePrikaz(val)}}
                />
              </div>
            </Col>
            <hr />
          </Row>
        );
      })}
    </Row>
  );
}
