import {Outlet} from "react-router-dom";
import Header from "./Header";
import React from "react";
import {Container} from "react-bootstrap";
import "./Footer";
import {Breadcrumbs} from "../components/Breadcrumbs";
import Footer from "./Footer";

export default function Layout() {
  return (
    <>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      <Header />

      <Breadcrumbs />
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Container fluid className={"mb-4"}>
        <Outlet />
      </Container>

      <Footer />
    </>
  );
}
