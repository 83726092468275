import React from "react";
import CONSTANTS from "../Constants";
import PrivateRoute from "../pages/auth/PrivateRoute";
import Loading from "../components/Loading";

const WrapperFetchData = React.lazy(() => import("../components/WrapperFetchData"));
const GroupTablePage = React.lazy(() => import("../pages/entity/group/GroupTablePage"));
const GroupPage = React.lazy(() => import("../pages/entity/group/GroupPage"));
const GroupDocsPage = React.lazy(() =>
  import("../pages/entity/group/docs/GroupDocsPage"),
);

export function RouteGroupId() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <WrapperFetchData url={CONSTANTS.URL.GET_GROUPS_AND_USERS}>
          <GroupPage />
        </WrapperFetchData>
      </React.Suspense>
    </PrivateRoute>
  );
}

export function RouteGroupDocs() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <WrapperFetchData url={CONSTANTS.URL.GET_GROUPS_AND_USERS}>
          <GroupDocsPage />
        </WrapperFetchData>
      </React.Suspense>
    </PrivateRoute>
  );
}

export function RouteGroupIndex() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <GroupTablePage />
      </React.Suspense>
    </PrivateRoute>
  );
}
