import React from "react";
import "./Template.css";

export default function TemplateSvidPlus(data) {
  return (
    "<html>\n" +
    "<head>\n" +
    '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>\n' +
    '    <meta http-equiv="Content-Style-Type" content="text/css"/>\n' +
    '    <meta name="generator" content="Aspose.Words for .NET 21.11.0"/>\n' +
    "    <title></title>\n" +
    '    <style type="text/css">.body2 {\n' +
    "        line-height: 108%;\n" +
    "        font-family: Calibri;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    p {\n" +
    "        margin: 0pt 0pt 8pt\n" +
    "    }\n" +
    "\n" +
    "    table {\n" +
    "        margin-top: 0pt;\n" +
    "        margin-bottom: 8pt\n" +
    "    }\n" +
    "\n" +
    "    .Footer {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    .Header {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    " @media print {\n" +
    "    .templateSvidPlus {\n" +
    "        padding-left: 30px;\n" +
    "    }\n" +
    "} " +
    "    .TableGrid {\n" +
    "    }    @page {\n" +
    "        margin: 0;\n" +
    "    }" +
    "    .content {\n" +
    "        padding-left: 3em;\n" +
    "    }\n" +
    "\n</style>\n" +
    "</head>\n" +
    "<body>\n" +
    "<div class='templateSvidPlus'>\n" +
    '    <p style="line-height:108%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="line-height:108%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="line-height:108%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="line-height:108%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="line-height:108%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <table cellspacing="0" cellpadding="0" class="TableGrid"\n' +
    '           style="width:531.6pt; margin-bottom:0pt; border-collapse:collapse">\n' +
    "        <tr>\n" +
    '            <td colspan="3" style="width:378.8pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:8pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:spaces\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Фамилия</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:8pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Имя Отчество</span></p></td>\n" +
    '            <td rowspan="3" style="width:131.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Общество с ограниченной ответственностью «Первый Профессиональный институт эстетики» </span>\n" +
    "                </p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">город Москва</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td colspan="3" style="width:378.8pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:7pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">15 сентября 1987 года</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:6pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td colspan="3" style="width:378.8pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt"><span\n' +
    '                    style="width:249.5pt; display:inline-block; -aw-tabstop-align:left; -aw-tabstop-pos:249.5pt">&#xa0;</span><span\n' +
    "                    style=\"font-family:'Times New Roman'; font-size:11pt\">Диплом о высшем</span></p>\n" +
    '                <p style="margin-bottom:6pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">образовании, 2014 год.</span>\n' +
    "                </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td rowspan="20" style="width:131.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; font-size:7pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt"><span style="font-family:\'Times New Roman\'; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-size:11pt\">121232412121</span></p>\n" +
    '                <p style="margin-bottom:0pt; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:spaces\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span\n" +
    "                        style=\"font-family:'Times New Roman'\">111212</span></p>\n" +
    '                <p style="margin-bottom:0pt; font-size:7pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">06 ноября 2021 года</span>\n' +
    "                </p>\n" +
    '                <p style="margin-bottom:0pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; font-size:8pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt"><br/><span style="font-family:\'Times New Roman\'; -aw-import:spaces">&#xa0;&#xa0; </span><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-size:11pt\">06 ноября 2021</span></p>\n" +
    '                <p style="margin-bottom:0pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; font-size:8pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; font-size:13pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; line-height:110%; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Мастер по наращиванию ресниц, 5 категории (разряда)</span>\n" +
    "                </p>\n" +
    '                <p style="margin-bottom:0pt; line-height:110%; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Код услуги – А…..f</span></p>\n" +
    '                <p style="margin-bottom:0pt; line-height:110%; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">F</span></p>\n" +
    '                <p style="margin-bottom:0pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; font-size:9pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:right"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:spaces\">&#xa0;</span><span\n" +
    "                        style=\"font-family:'Times New Roman'; font-size:11pt\">И.И.Иванов</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:right; font-size:8pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:right; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">И.И.Иванов</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:right; font-size:9pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:right; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">И.И.Иванов</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:11pt"><span style="-aw-import:ignore">&#xa0;</span></p></td>\n' +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:4pt"><span style="-aw-import:ignore">&#xa0;</span></p></td>\n' +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:4pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:4pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:11pt"><span style="-aw-import:ignore">&#xa0;</span></p></td>\n' +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:11pt"><span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p style="margin-bottom:0pt; font-size:11pt"><span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p style="margin-bottom:0pt; font-size:11pt"><span style="-aw-import:ignore">&#xa0;</span></p></td>\n' +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span>Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; text-align:center; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">12</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span>Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; text-align:center; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">6</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span>Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; text-align:center; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">12</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span>Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; text-align:center; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">6</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span>Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; text-align:center; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">12</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span>Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; text-align:center; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">6</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span>Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; text-align:center; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">12</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span>Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; text-align:center; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">6</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span>Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; text-align:center; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">12</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span>Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; text-align:center; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">6</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="-aw-import:ignore">&#xa0;</span></p></td>\n' +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:208.95pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt; font-size:11pt"><span style="-aw-import:ignore">&#xa0;</span></p></td>\n' +
    '            <td style="width:60.05pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:88.2pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:6pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "    </table>\n" +
    '    <p style="line-height:108%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    "    </p></div>\n" +
    "</div>\n" +
    "</body>\n" +
    "</html>"
  );
}
