import React from "react";
import PrivateRoute from "../pages/auth/PrivateRoute";
import Loading from "../components/Loading";

const WrapperFetchData = React.lazy(() => import("../components/WrapperFetchData"));
const ArchiveTablePage = React.lazy(() =>
  import("../pages/entity/archive/ArchiveTablePage"),
);

export function RouteArchiveId() {
  return (
    <PrivateRoute>
      {/*<WrapperFetchData*/}
      {/*  target={GroupPage}*/}
      {/*  url={CONSTANTS.URL.GET_GROUPS_AND_USERS}*/}
      {/*/>*/}
    </PrivateRoute>
  );
}

export function RouteArchiveIndex() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <ArchiveTablePage />
      </React.Suspense>
    </PrivateRoute>
  );
}
