import React from "react";
import {createRoot} from "react-dom/client";
import store from "./app/store";
import {Provider} from "react-redux";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import reportWebVitals from './reportWebVitals';

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <div className="body d-flex flex-column h-100">
      <Provider store={store}>
        <App />
      </Provider>
    </div>
  </React.StrictMode>,
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
