import React, {useEffect} from "react";
import {Button, Card, Col, FormControl, InputGroup} from "react-bootstrap";
import {useFieldArray, useForm} from "react-hook-form";
import DateCreateFromParser from "../../components/utils/dateCreateFromParser";

export default function FormPrikaz({defaultData, index, templateData, setTemplateData}) {
  // console.log('defaultData', defaultData)

  const {control, register, handleSubmit, watch} = useForm({defaultValues: defaultData});
  const {fields, append, remove} = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "students", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const onSubmit = (data) => console.log(data);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      const NewData = value;

      NewData.date = DateCreateFromParser(NewData.date);
      NewData.date_from = DateCreateFromParser(NewData.date_from);
      NewData.date_to = DateCreateFromParser(NewData.date_to);

      const NewTemplateData = templateData;
      NewTemplateData[index] = NewData;
      setTemplateData([...NewTemplateData]);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Col md={"5"}>
        <h3>Форма создания документа</h3>
        <Card>
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                <div className="col-8 my-1">
                  <label htmlFor="date" className="form-label">
                    Дата
                  </label>
                  <FormControl type="date" id="date" {...register("date")} />
                </div>

                <div className="col-4 my-1">
                  <label htmlFor="prikazNum" className="form-label">
                    Номер
                  </label>
                  <FormControl type="text" id="prikazNum" {...register("prikazNum")} />
                </div>

                <div className="col-12">
                  <label htmlFor="course" className="form-label">
                    Курс программы
                  </label>
                  <FormControl
                    type="text"
                    className="mb-2"
                    id="course"
                    {...register("course.title")}
                  />
                  <label htmlFor="num2" className="form-label">
                    Количество академических часов
                  </label>{" "}
                  <FormControl
                    type="number"
                    min={1}
                    className="num"
                    id="course"
                    {...register("course.hours")}
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="date_from" className="form-label">
                    Дата с
                  </label>{" "}
                  <FormControl
                    type="date"
                    className="dateInput"
                    id="date_from"
                    {...register("date_from")}
                  />{" "}
                  <label htmlFor="date_to" className="form-label">
                    Дата по
                  </label>{" "}
                  <FormControl
                    type="date"
                    className="dateInput"
                    id="date_to"
                    {...register("date_to")}
                  />
                </div>

                <h4>
                  Слушатели:{" "}
                  <Button
                    onClick={() => append({name: ""})}
                    size={"sm"}
                    variant={"success"}
                  >
                    +
                  </Button>
                </h4>
                <div className="col-12">
                  {fields.map((field, index) => (
                    <InputGroup className="input-group px-2 mb-3" key={field.id + "box"}>
                      <InputGroup.Text>ФИО</InputGroup.Text>
                      <FormControl
                        type="text"
                        key={field.id + "fullName"} // important to include key with field's id
                        {...register(`students.${index}.fullName`)}
                      />
                      <Button onClick={() => remove(index)} variant={"warning"}>
                        -
                      </Button>
                    </InputGroup>
                  ))}
                </div>
              </div>
            </form>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
