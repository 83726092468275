import Config from "./Config";
import GeneratorURL from "./GeneratorURL";

const {useQuery} = require("react-query");
const axios = require("axios");

export default function useMasterPutFetch({name, urlEntity, itemId, data, urlItem}) {
  const config = Config();
  const finalURL = GeneratorURL({urlEntity, itemId, urlItem});

  return useQuery(
    [name, itemId],
    async () => {
      const res = await axios.put(
        finalURL,
        {
          ...data,
        },
        config,
      );
      return res.data;
    },
    {
      retry: false,
      keepPreviousData: false,
      enabled: false,
      // useErrorBoundary: true
    },
  );
}
