import React from "react";
import {Route, Routes} from "react-router-dom";
import PrivateRoute from "./pages/auth/PrivateRoute";
import Home from "./pages/Home";
import GenSvid from "./pages/genPrint/GenSvid";
import GroupAndUsers from "./pages/GroupAndUsers";
import Layout from "./layers/Layout";
import Path404 from "./pages/Page404";
import GetParm from "./pages/Get";
import GenProtokol from "./pages/genPrint/GenProtokol";
import WrapperFetchData from "./components/WrapperFetchData";
import GenPrikaz from "./pages/genPrint/GenPrikaz";
import GenAnketa from "./pages/genPrint/GenAnketa";
import GenVeditog from "./pages/genPrint/GenVeditog";
import GenDiploma from "./pages/genPrint/GenDiploma";
import LoginPage from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";
import LogoutPage from "./pages/auth/LogoutPage";
import HelpPage from "./pages/HelpPage";
import ArchiveTablePage from "./pages/entity/archive/ArchiveTablePage";
import {RouteCourseId, RouteCourseIndex} from "./routes/RouteCourse";
import {RouteProfile} from "./routes/RouteProfile";
import {
  RouteDisciplineId,
  RouteDisciplineIndex,
  RouteDisciplineNew,
} from "./routes/RouteDiscipline";
import {RouteStaffId, RouteStaffIndex} from "./routes/RouteStaff";
import {
  RouteCourseCategoryId,
  RouteCourseCategoryIndex,
} from "./routes/RouteCourseCategory";
import {RouteGroupDocs, RouteGroupId, RouteGroupIndex} from "./routes/RouteGroup";
import {RouteStudentDub, RouteStudentId, RouteStudentIndex} from "./routes/RouteStudent";
import {RouteArchiveIndex} from "./routes/RouteArchive";
import SupportPage from "./pages/SupportPage";

function RoutesWrapper() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />

        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="logout" element={<LogoutPage />} />

        <Route path="profile" element={<RouteProfile />} />

        <Route
          path="list"
          element={
            <PrivateRoute>
              <GroupAndUsers />
            </PrivateRoute>
          }
        />

        <Route path="course">
          <Route path=":id" element={<RouteCourseId />} />
          <Route index element={<RouteCourseIndex />} />
        </Route>

        <Route path="courseCategory">
          <Route path=":id" element={<RouteCourseCategoryId />} />
          <Route index element={<RouteCourseCategoryIndex />} />
        </Route>

        <Route path="discipline">
          <Route path=":id" element={<RouteDisciplineId />} />
          <Route path="new" element={<RouteDisciplineNew />} />
          <Route index element={<RouteDisciplineIndex />} />
        </Route>

        <Route path="staff">
          <Route path=":id" element={<RouteStaffId />} />
          <Route index element={<RouteStaffIndex />} />
        </Route>

        <Route path="student">
          <Route path=":id" element={<RouteStudentId />} />
          <Route path="dub" element={<RouteStudentDub />} />
          <Route index element={<RouteStudentIndex />} />
        </Route>

        <Route path="group">
          <Route path=":id" element={<RouteGroupId />} />
          <Route path=":id/docs" element={<RouteGroupDocs />} />
          <Route index element={<RouteGroupIndex />} />
        </Route>

        <Route path="archive">
          <Route
            path=":id"
            element={
              <PrivateRoute>
                <ArchiveTablePage />
              </PrivateRoute>
            }
          />

          <Route index element={<RouteArchiveIndex />} />
        </Route>

        <Route path="protokol">
          <Route path="group" element={<WrapperFetchData target={GenProtokol} />}>
            <Route path=":groupId" element={<WrapperFetchData target={GenProtokol} />} />
          </Route>
          <Route index element={<GenProtokol />} />
        </Route>

        <Route path="/svid">
          <Route path="group" element={<WrapperFetchData target={GenSvid} />}>
            <Route path=":groupId" element={<WrapperFetchData target={GenSvid} />} />
          </Route>
          <Route index element={<GenSvid />} />
        </Route>

        <Route path="/diploma">
          <Route path="group" element={<WrapperFetchData target={GenDiploma} />}>
            <Route path=":groupId" element={<WrapperFetchData target={GenDiploma} />} />
          </Route>
          <Route index element={<GenDiploma />} />
        </Route>

        <Route path="/veditog">
          <Route path="group" element={<WrapperFetchData target={GenVeditog} />}>
            <Route path=":groupId" element={<WrapperFetchData target={GenVeditog} />} />
          </Route>
          <Route index element={<GenVeditog />} />
        </Route>

        <Route path="/prikaz">
          <Route path="group" element={<WrapperFetchData target={GenPrikaz} />}>
            <Route path=":groupId" element={<WrapperFetchData target={GenPrikaz} />} />
          </Route>
          <Route index element={<GenPrikaz title={"1231321"} />} />
        </Route>

        <Route path="/anketa">
          <Route path="group" element={<WrapperFetchData target={GenAnketa} />}>
            <Route path=":groupId" element={<WrapperFetchData target={GenAnketa} />} />
          </Route>
          <Route index element={<GenAnketa />} />
        </Route>

        <Route path="/get" element={<GetParm />}>
          <Route path=":test" element={<GetParm />} />
        </Route>
        <Route path="help" element={<HelpPage />} />
        <Route path="support" element={<SupportPage />} />

        <Route path="*" element={<Path404 />} />
      </Route>
    </Routes>
  );
}

export default RoutesWrapper;
