import React from "react";

export default function SupportPage() {
  return (
    <>
      <h1>Поддержка и помощь с программой</h1>

      <p>
        У вас есть вопросы? сложности? пожелания и предложения? или иная ситуация, вы
        можете связаться с разработчиком для консультации через телеграм -{" "}
        <a href={"https://t.me/IOtrue"} target={"_blank"} rel="noreferrer">
          https://t.me/IOtrue
        </a>
        , Максим.
      </p>
      <hr />
    </>
  );
}
