import React from "react";
import CONSTANTS from "../Constants";
import PrivateRoute from "../pages/auth/PrivateRoute";
import Loading from "../components/Loading";

const WrapperFetchData = React.lazy(() => import("../components/WrapperFetchData"));
const CourseTablePage = React.lazy(() =>
  import("../pages/entity/course/CourseTablePage"),
);
const CoursePage = React.lazy(() => import("../pages/entity/course/CoursePage"));

export function RouteCourseId() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <WrapperFetchData url={CONSTANTS.URL.GET_COURSE}>
          <CoursePage />
        </WrapperFetchData>
      </React.Suspense>
    </PrivateRoute>
  );
}

export function RouteCourseIndex() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <CourseTablePage />
      </React.Suspense>
    </PrivateRoute>
  );
}
