import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Nav, Navbar, NavDropdown, NavLink} from "react-bootstrap";
import {Link} from "react-router-dom";
import {
  BsBoxArrowInLeft,
  BsBoxArrowRight,
  BsPersonFill,
  BsPersonPlusFill,
  BsPersonSquare,
} from "react-icons/bs";
import CheckCookieAndKey from "../pages/auth/components/CheckCookieAndKey";
import {logout} from "../pages/auth/components/authSlice";

export default function UserBar() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // console.log("auth", auth)
  if (auth?.isAuth) {
    return (
      <>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <NavDropdown
              align={"end"}
              id="nav-dropdown-dark-example"
              title={
                <>
                  <BsPersonFill /> {auth?.user?.name}
                </>
              }
              menuVariant="dark"
            >
              <NavDropdown.Item as={Link} to="/profile">
                <BsPersonSquare /> Профиль
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/logout"
                onClick={() => {
                  localStorage.removeItem("user");
                  dispatch(logout());
                }}
              >
                <BsBoxArrowRight /> Выход
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </>
    );
  } else {
    return (
      <>
        <CheckCookieAndKey />
        <Nav>
          <Navbar.Collapse>
            <NavLink as={Link} to="/register">
              <BsPersonPlusFill /> Регистрация
            </NavLink>
            <NavLink as={Link} to="/login">
              <BsBoxArrowInLeft /> Вход
            </NavLink>
          </Navbar.Collapse>
        </Nav>
      </>
    );
  }
}
