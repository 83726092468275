import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import "./App.css";
import RoutesWrapper from "./Routes";
import {QueryClient, QueryClientProvider} from "react-query";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <RoutesWrapper />
        </ErrorBoundary>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
