import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

/**
 * A wrapper around the element which checks if the user is authenticated
 * If authenticated, renders the passed element
 * If not authenticated, redirects the user to Login page.
 */

const PrivateElement = ({children}) => {
  const auth = useSelector((state) => state.auth);
  let location = useLocation();
  if (!auth?.isAuth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{from: location}} replace />;
  }

  return children;
  // return user?.token ? (
  //
  //   children
  // ) : (
  //   <Navigate to="/login" state={{from: location}}/>
  // )
};

export default PrivateElement;
