import React from "react";
import CONSTANTS from "../Constants";
import PrivateRoute from "../pages/auth/PrivateRoute";
import Loading from "../components/Loading";
import DoublePage from "../pages/entity/student/double/DoublePage";

const WrapperFetchData = React.lazy(() => import("../components/WrapperFetchData"));
const StudentTablePage = React.lazy(() =>
  import("../pages/entity/student/StudentTablePage"),
);
const StudentPage = React.lazy(() => import("../pages/entity/student/StudentPage"));

export function RouteStudentId() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <WrapperFetchData url={CONSTANTS.URL.GET_STUDENT}>
          <StudentPage />
        </WrapperFetchData>
      </React.Suspense>
    </PrivateRoute>
  );
}

export function RouteStudentDub() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <DoublePage />
      </React.Suspense>
    </PrivateRoute>
  );
}

export function RouteStudentIndex() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <StudentTablePage />
      </React.Suspense>
    </PrivateRoute>
  );
}
