import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {PrintElem} from "../../../../components/utils/PrintElem";
import FormProtokol from "../../../genPrint/FormProtokol";
import TemplateProtokol from "../../../../components/templates/TemplateProtokol";
import TemplateVeditog from "../../../../components/templates/TemplateVeditog";
import FormVeditog from "../../../genPrint/FormVeditog";

export default function NodeSwitch({node, props}) {
  const {group, groupIndex, getValues} = props;
  console.log("31fgvdgdgdfg", getValues(`groups[${groupIndex}].students`));
  console.log("watch");
  const students = getValues(`groups[${groupIndex}]`);
  //TODO. Короче. Чтобы обновлять данные по одному студенту в полях и при этом не терялся фокус и не запускать лишний (на самом деле не лишний) рендер всего интерфейса этих компонентов, нуден этот стейт ВЫШЕ. Однако, чтобы всё же обновить родительский "стейт" желательно добавить кнопку сохранить, иначе при этой фигне опять теряется фокус. Либо как-то использовать редакс... Эта todo будет ждать своего звёздного часа - RIP 16.02.2022
  // useDidMountEffect(()=>{ //а эта бесконечный рендер стоп.
  //   setGroupData({...localGroupData}) //тут должна быть кнопка.
  // },[localGroupData])
  props.watch();
  switch (node.node) {
    case "Protocol":
      return (
        <React.Fragment key={"NodeSwitch" + node.node}>
          <Row key={"row-" + node.node}>
            <FormProtokol props={props} />
            <Col md={"7"} key={"col-" + groupIndex}>
              <h3>
                Пример{" "}
                <Button
                  onClick={() => PrintElem("content-protokol-" + groupIndex)}
                  size={"sm"}
                >
                  Печать
                </Button>
              </h3>
              <div id={"content-protokol-" + groupIndex} className={"PrintPreview"}>
                <div
                  className="wrapperPrint body2"
                  dangerouslySetInnerHTML={{__html: TemplateProtokol(students)}}
                />
              </div>
            </Col>
            <hr />
          </Row>
        </React.Fragment>
      );
    case "Itog vedomost":
      return (
        <React.Fragment key={"NodeSwitch" + node.node}>
          <Row key={"row-" + node.node}>
            <FormVeditog props={props} />
            <Col md={"7"} key={"col-" + groupIndex}>
              <h3>
                Пример{" "}
                <Button
                  onClick={() => PrintElem("content-Itogvedomost-" + groupIndex)}
                  size={"sm"}
                >
                  Печать
                </Button>
              </h3>
              <div id={"content-Itogvedomost-" + groupIndex} className={"PrintPreview"}>
                <div
                  className="wrapperPrint body2"
                  dangerouslySetInnerHTML={{__html: TemplateVeditog(students)}}
                />
              </div>
            </Col>
            <hr />
          </Row>
        </React.Fragment>
      );
    case "Svidetelstvo s prisvoeniem kvalifikacii":
      return (
        <>
          свидетельство ещё не готово
          {/*{students.map((student, studentIndex) => {*/}
          {/*  return <React.Fragment key={'NodeSwitch' + studentIndex}>*/}
          {/*    <Row key={'row-' + studentIndex}>*/}
          {/*      <FormSvid localGroupData={localGroupData} setLocalGroupData={setLocalGroupData} student={student}*/}
          {/*                studentIndex={studentIndex}/>*/}
          {/*      <Col md={'7'} key={'col-' + studentIndex}>*/}
          {/*        <h3>Пример <Button onClick={() => PrintElem("contentSved" + studentIndex)} size={"sm"}>Печать</Button>*/}
          {/*        </h3>*/}
          {/*        <div id={"contentSved" + studentIndex} className={"PrintPreview fixSved"}>*/}
          {/*          <div className="wrapperPrint body2"*/}
          {/*               dangerouslySetInnerHTML={{__html: TemplateSvid(student)}}/>*/}
          {/*        </div>*/}
          {/*      </Col>*/}
          {/*      <hr/>*/}
          {/*    </Row>*/}
          {/*    <Row key={'row2-' + studentIndex}>*/}
          {/*      <FormSvidPlus groupData={group} isComplex={isComplex}*/}
          {/*                    courseIndex={courseIndex} student={student} studentIndex={studentIndex}/>*/}
          {/*      <Col md={'7'} key={'col2-' + studentIndex}>*/}
          {/*        <h3>Пример <Button onClick={() => PrintElem("contentSvedPlus" + studentIndex)}*/}
          {/*                           size={"sm"}>Печать</Button>*/}
          {/*        </h3>*/}
          {/*        <div id={"contentSvedPlus" + studentIndex} className={"PrintPreview fixSved"}>*/}
          {/*          <div className="wrapperPrint body2"*/}
          {/*               dangerouslySetInnerHTML={{__html: TemplateSvidPlus(student)}}/>*/}
          {/*        </div>*/}
          {/*      </Col>*/}
          {/*      <hr/>*/}
          {/*    </Row>*/}
          {/*    <CardStudents students={[student]} course={group.course}/>*/}
          {/*  </React.Fragment>*/}
          {/*})}*/}
        </>
      );
    default:
      return "Для этой ноды формирование документов ещё не разработано.";
  }
}
