import React from "react";
import "./Template.css";

export default function TemplateDiploma(data) {
  // console.log('TemplateDiploma', data)
  // return "class="templateDiploma" +
  return (
    "<html>\n" +
    "<head>\n" +
    '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>\n' +
    '    <meta http-equiv="Content-Style-Type" content="text/css"/>\n' +
    '    <meta name="generator" content="Aspose.Words for .NET 21.12.0"/>\n' +
    "    <title></title>\n" +
    '    <style type="text/css">body {\n' +
    "        line-height: 108%;\n" +
    "        font-family: Calibri;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    p {\n" +
    "        margin: 0pt 0pt 8pt\n" +
    "    }\n" +
    "\n" +
    "    table {\n" +
    "        margin-top: 0pt;\n" +
    "        margin-bottom: 8pt\n" +
    "    }\n" +
    "\n" +
    "    .BodyText {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        widows: 0;\n" +
    "        orphans: 0;\n" +
    "        font-family: 'Times New Roman';\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    .Footer {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    .Header {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    span.a1 {\n" +
    "        font-family: 'Times New Roman'\n" +
    "    }\n" +
    "\n" +
    "    .TableGrid {\n" +
    "    }</style>\n" +
    "</head>\n" +
    "<body>\n" +
    '<div class="templateDiploma">\n' +
    '    <table cellspacing="0" cellpadding="0" class="TableGrid"\n' +
    '           style="width:786.75pt; margin-right:9pt; margin-left:9pt; margin-bottom:0pt; border-collapse:collapse; float:left">\n' +
    '        <tr style="height:559.35pt">\n' +
    '            <td style="width:407.15pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p class="BodyText"\n' +
    '                                                                                                       style="text-align:center; line-height:150%; font-size:14pt">\n' +
    '                <span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="font-weight:bold; -aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="font-weight:bold">Общество</span><span\n' +
    '                        style="font-weight:bold; letter-spacing:-0.1pt"> </span><span\n' +
    '                        style="font-weight:bold">с</span><span\n' +
    '                        style="font-weight:bold; letter-spacing:-0.1pt"> </span><span style="font-weight:bold">ограниченной</span><span\n' +
    '                        style="font-weight:bold; letter-spacing:-0.1pt"> </span><span style="font-weight:bold">ответственностью</span>\n' +
    "                </p>\n" +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="font-weight:bold">"Первый Профессиональный Институт Эстетики"</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="line-height:150%; font-size:8pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="line-height:150%; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="margin-top:18pt; text-align:center; line-height:150%; font-size:14pt"><span>13131</span>\n' +
    "                </p>\n" +
    '                <p class="BodyText" style="margin-top:18pt; text-align:center; line-height:150%; font-size:14pt"><span>Москва</span>\n' +
    "                </p>\n" +
    '                <p class="BodyText" style="margin-top:18pt; text-align:center; line-height:150%; font-size:14pt"><span>01 октября 2021 года</span>\n' +
    "                </p></td>\n" +
    '            <td style="width:358pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; font-weight:bold; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold\">Иванов</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold\">Иван</span><span\n" +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold; letter-spacing:-0.65pt\"> </span><span\n" +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold\">Иванович</span></p>\n" +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="font-weight:bold; -aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="font-weight:bold; -aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="font-weight:bold; -aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="font-weight:bold; -aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span>ООО "Первый Профессиональный Институт Эстетики"</span>\n' +
    "                </p>\n" +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span>01 октября 2021 года</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt">\n' +
    '                    <span>"Сестринское дело в косметологии"</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="font-size:14pt"><span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:14pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p style="margin-top:6pt; margin-bottom:12pt; text-align:right; font-size:14pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Иванов А. А.</span></p>\n" +
    '                <p style="margin-bottom:12pt; text-align:right; font-size:14pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Петров</span><span\n" +
    "                        style=\"font-family:'Times New Roman'; letter-spacing:-0.05pt\"> </span><span\n" +
    "                        style=\"font-family:'Times New Roman'\">Б. Б.</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:right; font-size:14pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Петров</span><span\n" +
    "                        style=\"font-family:'Times New Roman'; letter-spacing:-0.05pt\"> </span><span\n" +
    "                        style=\"font-family:'Times New Roman'\">Б. Б.</span></p></td>\n" +
    "        </tr>\n" +
    "    </table>\n" +
    "</body>\n" +
    "</html>"
  );
}
