import React from "react";
import {Alert, Button, Col, Form, Stack} from "react-bootstrap";
import {useForm} from "react-hook-form";

export default function DoubleSideRight({
  dataStudentsGroupBy,
  selectLeftInput,
  setSelectLeftInput,
  setRadioLeftInput,
  radioLeftInput,
  setCheckRightInput,
  checkRightInput,
  fetchPut,
}) {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm();

  const onSubmit = (data) => {
    fetchPut.refetch();
    // setRadioLeftInput(false)
    // setCheckRightInput([])
    // console.log('onSubmit', data)
  };
  React.useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      // console.log(value, name, type)
      // console.log('value', value.checkSideRight)

      setCheckRightInput(value.checkSideRight.filter((x) => x !== radioLeftInput));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    const newCheckSideRight = checkRightInput.filter((x) => x !== radioLeftInput);
    setValue("checkSideRight", newCheckSideRight);
    setCheckRightInput(newCheckSideRight);
  }, [radioLeftInput]);

  return (
    <>
      <Col md={6}>
        <Alert variant={"secondary"} className={"py-2 pt-2"}>
          Выберите студентов для объединения их с левой стороной
        </Alert>
        <Alert variant={"warning"} className={"py-2 pt-2"}>
          <b>Внимание!</b> Дата рождения, гражданство и СНИЛС будут безвозвратно потеряны
          у студентов в правом списке при объединении! Убедитесь, что эти данные у
          студента слева верны.
        </Alert>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {dataStudentsGroupBy[selectLeftInput].map((student, index) => {
            const type = "checkbox";
            return (
              <div key={`leftSide-${type}-${index}`} className="mb-3">
                <Stack direction="horizontal" gap={3}>
                  <div>
                    {/*{!(checkRightInput.includes(radioLeftInput) && radioLeftInput !== student.id ) ? '1': '2'}*/}
                    <Form.Check
                      type={type}
                      name={"rightSide"}
                      value={student.id}
                      disabled={radioLeftInput == student.id}
                      label={
                        <>
                          {student.fullName} (id:{" "}
                          <a
                            href={"/student/" + student.id}
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            {student.id}
                          </a>
                          )
                        </>
                      }
                      id={`leftSide-${type}-${index}`}
                      {...register("checkSideRight", {required: true})}
                    />
                  </div>
                  <div className="ms-auto"></div>
                </Stack>
              </div>
            );
          })}
          {/*{console.log('rradio', radioLeftInput)}*/}
          <Button
            variant={"success"}
            type={"submit"}
            disabled={!checkRightInput.length || !radioLeftInput}
          >
            Объединить
          </Button>
        </Form>
      </Col>
    </>
  );
}
