import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {logout} from "./components/authSlice";
import {useNavigate} from "react-router-dom";

/**
 * A wrapper around the element which checks if the user is authenticated
 * If authenticated, renders the passed element
 * If not authenticated, redirects the user to Login page.
 */

export default function LogoutPage() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("user");
    dispatch(logout());
    navigate("/");
  }, []);

  return <>Вы успешно вышли</>;
}
