import React from "react";
import CONSTANTS from "../Constants";
import PrivateRoute from "../pages/auth/PrivateRoute";
import Loading from "../components/Loading";

const WrapperFetchData = React.lazy(() => import("../components/WrapperFetchData"));
const StaffTablePage = React.lazy(() => import("../pages/entity/staff/StaffTablePage"));
const StaffPage = React.lazy(() => import("../pages/entity/staff/StaffPage"));

export function RouteStaffId() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <WrapperFetchData url={CONSTANTS.URL.GET_STAFF}>
          <StaffPage />
        </WrapperFetchData>
      </React.Suspense>
    </PrivateRoute>
  );
}

export function RouteStaffIndex() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <StaffTablePage />
      </React.Suspense>
    </PrivateRoute>
  );
}
