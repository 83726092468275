import CONSTANTS from "../Constants";

export default function GetCOURSE_PRINT_NODE(node) {
  return CONSTANTS.COURSE_PRINT_NODE.find((x) => {
    if (x.node === node) {
      return node;
    }
    return false;
  });
}
