import React from "react";
import CONSTANTS from "../Constants";
import PrivateRoute from "../pages/auth/PrivateRoute";
import Loading from "../components/Loading";

const WrapperFetchData = React.lazy(() => import("../components/WrapperFetchData"));
const CourseCategoryTablePage = React.lazy(() =>
  import("../pages/entity/courseCategory/CourseCategoryTablePage"),
);
const CourseCategoryPage = React.lazy(() =>
  import("../pages/entity/courseCategory/CourseCategoryPage"),
);

export function RouteCourseCategoryId() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <WrapperFetchData url={CONSTANTS.URL.GET_COURSE_CATEGORY}>
          <CourseCategoryPage />
        </WrapperFetchData>
      </React.Suspense>
    </PrivateRoute>
  );
}

export function RouteCourseCategoryIndex() {
  return (
    <PrivateRoute>
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        <CourseCategoryTablePage />
      </React.Suspense>
    </PrivateRoute>
  );
}
