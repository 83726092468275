import React from "react";
import {Button, Card, Col, FormControl, InputGroup} from "react-bootstrap";
import {useFieldArray} from "react-hook-form";
import dateFormat from "../../components/utils/dateFormat";
import findStaff from "../../components/utils/findStaff";

export default function FormProtokol({props}) {
  const {group, groupIndex, control, register, getValues} = props;
  let newData = group;

  console.log("FormProtokol props", props);
  console.log("FormProtokol getValues", getValues());
  console.log("FormProtokol", newData);

  //определяем начальные данные тут
  newData = {
    ...newData,
    // num1: newData.id,
    // num2: newData.id,
    date: dateFormat(newData?.created_at ? newData?.created_at : new Date()),
    namePredsedatel: findStaff(newData, 1),
    // name_Chel_com: findStaff(newData, 2),
    nameSecretar: findStaff(newData, 3),
    commissionStaff: newData?.staff ? [...newData?.staff] : [],
  };

  const {
    fields: studentsFields,
    append: studentsAppend,
    remove: studentsRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `groups[${groupIndex}].students`, // unique name for your Field Array
    // name: `groups[${groupIndex}].course.students`, // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });
  const {
    fields: commissionStaffFields,
    append: commissionStaffAppend,
    remove: commissionStaffRemove,
  } = useFieldArray({
    control,
    name: `groups[${groupIndex}].commissionStaff`,
  });
  console.log("studentsFields", studentsFields);
  return (
    <>
      <Col md={"5"}>
        <h4>Форма документа</h4>
        <Card>
          <Card.Body>
            <div className="row g-3">
              <div className="col-sm-12">
                <label htmlFor="num1" className="form-label">
                  Номер1
                </label>{" "}
                <FormControl
                  type="text"
                  className="num"
                  id="num1"
                  {...register(`groups[${groupIndex}].num1`)}
                />{" "}
                /{" "}
                <label htmlFor="num2" className="form-label">
                  Номер2
                </label>{" "}
                <FormControl
                  type="text"
                  className="num"
                  id="num2"
                  {...register(`groups[${groupIndex}].num2`)}
                />
              </div>

              <div className="col-12">
                <label htmlFor="course" className="form-label">
                  Курс программы
                </label>
                <div className="input-group has-validation">
                  <FormControl
                    type="text"
                    className="num"
                    id="course"
                    {...register(`groups[${groupIndex}].course.title`)}
                  />
                </div>
              </div>

              <div className="col-12">
                <label htmlFor="date" className="form-label">
                  Дата
                </label>
                <FormControl
                  type="date"
                  id="date"
                  {...register(`groups[${groupIndex}].date`)}
                />
              </div>

              <div className="col-12">
                <label htmlFor="namePredsedatel" className="form-label">
                  Председатель
                </label>
                <div className="input-group has-validation">
                  <FormControl
                    type="text"
                    id="namePredsedatel"
                    {...register(`groups[${groupIndex}].namePredsedatel`)}
                  />
                </div>
              </div>

              <div className="col-12">
                <label htmlFor="name_Chel_com" className="form-label">
                  Члены комиссии{" "}
                  <Button
                    onClick={() => commissionStaffAppend({name: ""})}
                    size={"sm"}
                    variant={"success"}
                  >
                    +
                  </Button>
                </label>

                {commissionStaffFields?.map((field, index) => (
                  <InputGroup key={"commissionStaff-" + index}>
                    <FormControl
                      type="text"
                      id="nameSecretar"
                      {...register(
                        `groups[${groupIndex}].commissionStaff.${index}.fullName`,
                      )}
                    />
                    <Button
                      onClick={() => commissionStaffRemove(index)}
                      variant={"warning"}
                    >
                      -
                    </Button>
                  </InputGroup>
                ))}
              </div>

              <div className="col-12">
                <label htmlFor="nameSecretar" className="form-label">
                  Секретарь
                </label>
                <InputGroup>
                  <FormControl
                    type="text"
                    id="nameSecretar"
                    {...register(`groups[${groupIndex}].nameSecretar`)}
                  />
                </InputGroup>
              </div>
              <h4>
                Слушатели:{" "}
                <Button
                  onClick={() => studentsAppend({name: ""})}
                  size={"sm"}
                  variant={"success"}
                >
                  +
                </Button>
              </h4>
              <div className="col-12">
                {studentsFields?.map((field, index) => (
                  <div className={" mb-3"} key={index + "box"}>
                    <InputGroup>
                      <InputGroup.Text>ФИО</InputGroup.Text>
                      <FormControl
                        type="text"
                        key={field.id + "fullName"} // important to include key with field's id
                        {...register(`groups[${groupIndex}].students[${index}].fullName`)}
                      />
                      <Button onClick={() => studentsRemove(index)} variant={"warning"}>
                        -
                      </Button>
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text>Тестирование: % и оценка</InputGroup.Text>
                      <FormControl
                        aria-label="First name"
                        key={field.id + "testPr"}
                        {...register(`groups[${groupIndex}].students[${index}].testPr`)}
                      />
                      <FormControl
                        aria-label="Last name"
                        key={field.id + "testBall"}
                        {...register(`groups[${groupIndex}].students[${index}].testBall`)}
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text>Собеседование: № билета и оценка</InputGroup.Text>
                      <FormControl
                        aria-label="First name"
                        key={field.id + "sobBilet"}
                        {...register(`groups[${groupIndex}].students[${index}].sobBilet`)}
                      />
                      <FormControl
                        aria-label="Last name"
                        key={field.id + "sobBall"}
                        {...register(`groups[${groupIndex}].students[${index}].sobBall`)}
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text>Практ. навыки и общая оценка</InputGroup.Text>
                      <FormControl
                        aria-label="First name"
                        key={field.id + "sobBilet"}
                        {...register(
                          `groups[${groupIndex}].students[${index}].praktBall`,
                        )}
                      />
                      <FormControl
                        aria-label="Last name"
                        className={"border border-1 border-primary"}
                        key={field.id + "sobBall"}
                        {...register(`groups[${groupIndex}].students[${index}].endBall`)}
                      />
                    </InputGroup>
                  </div>
                ))}
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
