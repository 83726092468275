import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";

export default function ErrorFallback({error, resetErrorBoundary}) {
  console.log("error.message", error);
  return (
    <div className={"alert alert-danger"} role="alert">
      <p>Что-то пошло не так:</p>
      <pre>{error.message}</pre>
      <Button onClick={resetErrorBoundary}>Попробовать ещё раз</Button>{" "}
      <Link className={"btn btn-secondary"} to={"/"} onClick={resetErrorBoundary}>
        Не помогло? вернуться на главную
      </Link>
    </div>
  );
}
