import GroupBy from "../../../../components/GroupBy";

export default function GroupStudentsByFIO({
  getStudents,
  setDataStudents,
  setDataStudentsGroupBy,
}) {
  setDataStudents(getStudents);

  let students = [];
  let dobleStudents = [];
  students = getStudents.map((student) => {
    return {...student, namePlussurname: student.surname + " " + student.name};
  });
  const grouped = GroupBy(students, (student) => student.namePlussurname);
  grouped.forEach((student) => {
    if (student.length > 1) {
      dobleStudents.push(student);
    }
  });
  console.log("dobleStudents", dobleStudents);
  setDataStudentsGroupBy([...dobleStudents.sort().reverse()]);
}
