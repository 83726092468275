import React from "react";
import {Toast, ToastContainer} from "react-bootstrap";

export default function SendToast({
  variant = "",
  msg = "",
  title = "Уведомление",
  delay = 3000,
  show,
  setShow,
  autohide = false,
}) {
  return (
    <ToastContainer>
      <Toast
        className="d-inline-block m-1"
        bg={variant.toLowerCase() + " text-light"}
        onClose={() => setShow(false)}
        show={show}
        delay={delay}
        autohide={autohide}
      >
        <Toast.Header>
          {/*<img src="holder.js/20x20?text=%20" className="rounded me-2" alt=""/>*/}
          <strong className="me-auto">{title}</strong>
          {/*<small>11 mins ago</small>*/}
        </Toast.Header>
        <Toast.Body className={variant === ("Dark" || "Danger") ? "text-white" : ""}>
          {msg}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
