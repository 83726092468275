import CONSTANTS from "../../Constants";
import GeneratorURL from "./GeneratorURL";

const {useQuery} = require("react-query");
const axios = require("axios");

export default function useAuthLogin(data) {
  const finalURL = GeneratorURL({
    urlEntity: CONSTANTS.URL.POST_AUTH_LOGIN,
    addCenter: false,
    centerId: "",
  });
  return useQuery(
    ["getUserData", data],
    async () => {
      const res = await axios.post(finalURL, {
        email: data?.email,
        password: data?.password,
      });
      return res.data;
    },
    {
      retry: false,
      keepPreviousData: false,
      enabled: !!data,
    },
  );
}
