import React from "react";
import "./Template.css";

export default function TemplatePrikaz(data) {
  // console.log("TemplatePrikaz", data)

  function UsersForOne(users) {
    const html = users?.map((val, index) => {
      return (
        '        <li class="ListParagraph"\n' +
        "            style=\"margin-left:49.45pt; margin-bottom:0pt; line-height:150%; padding-left:0.2pt; font-family:'Times New Roman'; font-size:12pt\">\n" +
        "            <span>" +
        val.fullName +
        "</span></li>\n"
      );
    });
    return html?.join("");
  }

  // console.log('TEST', UsersFor(data?.users))
  return (
    "<html>\n" +
    "<head>\n" +
    '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>\n' +
    '    <meta http-equiv="Content-Style-Type" content="text/css"/>\n' +
    '    <meta name="generator" content="Aspose.Words for .NET 21.12.0"/>\n' +
    "    <title></title>\n" +
    '    <style type="text/css">.body2 {\n' +
    "        line-height: 108%;\n" +
    "        font-family: Calibri;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    p {\n" +
    "        margin: 0pt 0pt 8pt\n" +
    "    }\n" +
    "\n" +
    "    li, table {\n" +
    "        margin-top: 0pt;\n" +
    "        margin-bottom: 8pt\n" +
    "    }\n" +
    "\n" +
    "    .ListParagraph {\n" +
    "        margin-left: 36pt;\n" +
    "        margin-bottom: 8pt;\n" +
    "        line-height: 108%;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    .TableGrid {\n" +
    "    }</style>\n" +
    "</head>\n" +
    "<body>\n" +
    "<div>\n" +
    '    <p style="text-align:center; line-height:108%; border-bottom:0.75pt solid #000000; padding-bottom:1pt; font-size:12pt">\n' +
    "        <span style=\"font-family:'Times New Roman'; font-weight:bold; text-transform:uppercase\">«Первый профессиональный институт эстетики»</span>\n" +
    "    </p>\n" +
    '    <p style="text-align:center; line-height:108%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold; text-transform:uppercase\">Приказ</span></p>\n" +
    '    <table cellspacing="0" cellpadding="0" class="TableGrid"\n' +
    '           style="width:517.4pt; margin-bottom:0pt; border-collapse:collapse">\n' +
    "        <tr>\n" +
    '            <td style="width:176.35pt; vertical-align:top"><p style="margin-bottom:0pt; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">" +
    data?.date.dd +
    "." +
    data?.date.mm +
    "." +
    data?.date.yyyy +
    "</span></p></td>\n" +
    '            <td style="width:305.6pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:right; font-size:11pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">№</span></p></td>\n" +
    '            <td style="width:35.45pt; vertical-align:top">\n' +
    '                <div style="border-bottom-style:solid; border-bottom-width:0.75pt; padding-bottom:1pt"><p\n' +
    '                        style="margin-left:39.25pt; margin-bottom:0pt; text-indent:-39.25pt; font-size:11pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">" +
    (data?.prikazNum ? data?.prikazNum : 0) +
    "</span></p></div>\n" +
    "            </td>\n" +
    "        </tr>\n" +
    "    </table>\n" +
    '    <p style="text-align:center; line-height:normal; font-size:12pt"><span style="font-family:\'Times New Roman\'">г. Москва</span>\n' +
    "    </p>\n" +
    '    <p style="text-align:center; line-height:normal; font-size:12pt"><span style="font-family:\'Times New Roman\'">«О зачислении обучающихся по программам дополнительного профессионального образования, профессионального обучения»</span>\n' +
    "    </p>\n" +
    '    <p style="margin-bottom:0pt; line-height:normal; font-size:12pt"><span style="font-family:\'Times New Roman\'">На основании Положения «О правилах приёма слушателей на обучение по программам дополнительного профессионального образования, профессионального обучения»</span>\n' +
    "    </p>\n" +
    '    <p style="margin-top:12pt; margin-bottom:0pt; line-height:150%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; letter-spacing:1.9pt\">Приказываю</span><span\n" +
    "            style=\"font-family:'Times New Roman'; letter-spacing:1.5pt\">:</span></p>\n" +
    '    <p style="margin-bottom:0pt; line-height:normal; font-size:12pt"><span style="font-family:\'Times New Roman\'">Зачислить слушателей на обучение по программе профессионального Обучения «' +
    data?.course.title +
    "», реализуемой в объеме " +
    data?.course.hours +
    " академических часов в период:</span>\n" +
    "    </p>\n" +
    '    <p style="margin-top:12pt; margin-bottom:0pt; text-indent:35.4pt; line-height:150%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">С " +
    data?.date_from.dd +
    "." +
    data?.date_from.mm +
    "." +
    data?.date_from.yyyy +
    " по " +
    data?.date_to.dd +
    "." +
    data?.date_to.mm +
    "." +
    data?.date_to.yyyy +
    "</span></p>\n" +
    '    <p style="margin-bottom:0pt; line-height:150%; font-size:12pt"><span style="font-family:\'Times New Roman\'">Основание: подписанные договоры</span>\n' +
    "    </p>\n" +
    '    <ol type="1" style="margin:0pt; padding-left:0pt">\n' +
    UsersForOne(data?.students) +
    "    </ol>\n" +
    '    <p style="margin-bottom:0pt; line-height:150%; font-size:12pt"><span style="font-family:\'Times New Roman\'">Контроль за выполнением Приказа возлагаю на себя.</span>\n' +
    "    </p>\n" +
    '    <p style="line-height:108%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <table cellspacing="0" cellpadding="0" class="TableGrid"\n' +
    '           style="width:517.4pt; margin-bottom:0pt; border-collapse:collapse">\n' +
    "        <tr>\n" +
    '            <td style="width:205.55pt; vertical-align:top"><p style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">Генеральный директор</span></p></td>\n" +
    '            <td style="width:113.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; border-bottom-style:solid; border-bottom-width:0.75pt; padding-bottom:1pt">\n' +
    "                <span style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">(подпись)</span></p></td>\n" +
    '            <td style="width:198.45pt; vertical-align:top"><p style="margin-bottom:0pt; text-align:center"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">О.А. Краснова</span></p></td>\n" +
    "        </tr>\n" +
    "    </table>\n" +
    '    <p style="line-height:108%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></div>\n" +
    "</body>\n" +
    "</html>"
  );
}
