import React, {useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import TemplateSvid from "../../components/templates/TemplateSvid";
import FormSvid from "./FormSvid";
import {PrintElem} from "../../components/utils/PrintElem";
import TemplateSvidPlus from "../../components/templates/TemplateSvidPlus";
// import './docGen.css';

export default function GenSvid() {
  // console.log('GenProtokol defaultData', defaultData)

  const group = {students: [{name: 123}]};

  const [localGroupData, setLocalGroupData] = useState(group);

  // console.log('GenProtokol templateData', templateData)

  return (
    <Row>
      <Col md={"5"}>
        <h2>Свидетельство о профессии</h2>
      </Col>
      <Col md={"7"}>
        {/*<PrintAll Template={[TemplateSvid, TemplateSvidPlus]} templateData={localGroupData}/>*/}
      </Col>
      <hr />
      {localGroupData.students?.map((student, studentIndex) => {
        // console.log("templateData val", val)
        return (
          <React.Fragment key={"NodeSwitch" + studentIndex}>
            <Row key={"row-" + studentIndex}>
              <FormSvid
                localGroupData={localGroupData}
                setLocalGroupData={setLocalGroupData}
                student={student}
                studentIndex={studentIndex}
              />

              <Col md={"7"} key={"col-" + studentIndex}>
                <h3>
                  Пример{" "}
                  <Button
                    onClick={() => PrintElem("contentSved" + studentIndex)}
                    size={"sm"}
                  >
                    Печать
                  </Button>
                </h3>
                <div id={"contentSved" + studentIndex} className={"PrintPreview fixSved"}>
                  <div
                    className="wrapperPrint body2"
                    dangerouslySetInnerHTML={{__html: TemplateSvid(student)}}
                  />
                </div>
              </Col>
              <hr />
            </Row>
            <Row key={"row2-" + studentIndex}>
              {/*<FormSvidPlus groupData={localGroupData[0]} setGroupData={setLocalGroupData} student={student.students}*/}
              {/*              studentIndex={studentIndex}/>*/}
              <Col md={"7"} key={"col2-" + studentIndex}>
                <h3>
                  Пример{" "}
                  <Button
                    onClick={() => PrintElem("contentSvedPlus" + studentIndex)}
                    size={"sm"}
                  >
                    Печать
                  </Button>
                </h3>
                <div
                  id={"contentSvedPlus" + studentIndex}
                  className={"PrintPreview fixSved"}
                >
                  <div
                    className="wrapperPrint body2"
                    dangerouslySetInnerHTML={{__html: TemplateSvidPlus(student)}}
                  />
                </div>
              </Col>
              <hr />
            </Row>
          </React.Fragment>
        );
      })}
    </Row>
  );
}
