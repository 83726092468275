import React from "react";

export default function HelpPage() {
  return (
    <>
      <h1>Справка о программе</h1>
      <p>Возможно когда нибудь тут будет документация. Но такой задачи не стоит.</p>
      <hr />
      <h2>Работа с таблицами:</h2>
      <h3>Сортировка данных:</h3>
      <p>Сортировка данных</p>
    </>
  );
}
