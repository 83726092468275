import React from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";

const ProfilePage = ({x}) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  return (
    <div>
      <h3>Привет {auth.user.name}!</h3>
      <p>Тут будет профиль? Настройки и может что-то ещё. Когда нибудь.</p>
      <Button
        onClick={() => {
          // handlerButton()
          // auth.logout(() =>
          navigate("/logout", {state: {from: {pathname: "/profile"}}});
          // )
        }}
      >
        Выйти
      </Button>
    </div>
  );
};

export default ProfilePage;
