import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Alert, Button, FormControl} from "react-bootstrap";
import "./Auth.scss";
import SendToast from "../../components/SendToast";
import {useForm} from "react-hook-form";
import useAuthLogin from "../../components/fetch/useAuthLogin";
import Loading from "../../components/Loading";
import {useDispatch, useSelector} from "react-redux";
import {login} from "./components/authSlice";

function LoginPage() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loginForm, setLoginForm] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm();
  const {data: getAuth, isLoading, error} = useAuthLogin(loginForm);
  const [show, setShow] = useState(false);

  // console.log('auth2222', auth)
  const onSubmit = (data) => {
    // AuthLogin({data,users, setUsers})
    setLoginForm(data);
  };
  let navigate = useNavigate();
  let location = useLocation();

  let {from} = location.state || {from: {pathname: "/"}};

  useEffect(() => {
    if (getAuth?.data) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...getAuth.data,
        }),
      );
      dispatch(login(getAuth.data));
    }
    if (auth.isAuth) {
      navigate(from);
    }
    if (location.state && show === false) {
      setShow(true);
    }
  }, [getAuth, auth]);

  function LoginForm() {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>Авторизация</legend>
          <ul>
            <li>
              <label htmlFor="email">Email:</label>
              <FormControl type="email" {...register("email", {required: true})} />
            </li>
            <li>
              <label htmlFor="password">Пароль:</label>
              <FormControl type="password" {...register("password", {required: true})} />
            </li>
          </ul>
        </fieldset>
        <Button type={"submit"} className={"primary text-dark"}>
          Войти <Loading conditional={isLoading} size={"sm"} />
        </Button>
        <Button className={"text-dark"} onClick={() => navigate({pathname: "/register"})}>
          Нет аккаунта? зарегистрируйтесь!
        </Button>
      </form>
    );
  }

  if (auth.isAuth) {
    return (
      <div className={"AuthContainer"}>
        <SendToast
          variant={"danger"}
          title={"Вы не авторизованы!"}
          show={show}
          setShow={setShow}
          autohide={true}
        />

        <div className={"AuthBlock"}>
          <Alert className={"mb-1"} variant={"success"}>
            Вы уже успешно вошли!
          </Alert>
        </div>
      </div>
    );
  } else if (error) {
    return (
      <>
        <Alert className={"mb-1"} variant={"danger"}>
          Ошибка
          <pre className={"m-0"}>{error.message}</pre>
        </Alert>
      </>
    );
  } else {
    return (
      <div className={"AuthContainer"}>
        <SendToast
          variant={"danger"}
          title={"Вы не авторизованы!"}
          show={show}
          setShow={setShow}
          autohide={true}
        />

        <div className={"AuthBlock"}>
          <h2>Вход в систему!</h2>
          <LoginForm />
        </div>
      </div>
    );
  }
}

export default LoginPage;
