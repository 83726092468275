import React, {useEffect} from "react";
import {Card, Col, FormControl} from "react-bootstrap";
import {useForm} from "react-hook-form";
import DateCreateFromParser from "../../components/utils/dateCreateFromParser";

export default function FormSvid({
  localGroupData,
  setLocalGroupData,
  student,
  studentIndex,
}) {
  let newData = student;

  // console.log('localGroupData', localGroupData)
  // console.log('student', student)

  function findStaff(search, typeId) {
    let searchData = search?.staff?.find((x) => x.pivot.type === typeId);
    return searchData ? searchData.fullName : "";
  }

  newData = {
    ...newData,
    namePredsedatel: findStaff(newData, 1),
    name_Chel_com: findStaff(newData, 2),
    nameSecretar: findStaff(newData, 3),
  };

  findStaff(newData, 5);
  const {control, register, handleSubmit, watch} = useForm({defaultValues: newData});

  const onSubmit = (data) => console.log(data);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      const NewData = value;

      NewData.date = DateCreateFromParser(NewData.date);

      const NewTemplateData = localGroupData;
      NewTemplateData.students[studentIndex] = NewData;
      // console.log('NewTemplateData',NewTemplateData.students[studentIndex])
      setLocalGroupData({...NewTemplateData});
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Col md={"5"}>
        <h3>Форма создания документа</h3>
        <Card>
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                <div className="col-12 my-1">
                  <label htmlFor="aboutMe" className="form-label">
                    Организация
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      className="num"
                      id="aboutMe"
                      {...register("aboutMe")}
                    />
                  </div>
                </div>

                <div className="col-4 my-1">
                  <label htmlFor="regNum" className="form-label">
                    № регистрационный номер
                  </label>
                  <FormControl type="date" id="regNum" {...register("regNum")} />
                </div>

                <div className="col-4 my-1">
                  <label htmlFor="dateSend" className="form-label">
                    Дата выдачи
                  </label>
                  <FormControl type="date" id="dateSend" {...register("dateSend")} />
                </div>
                <div className="col-4 my-1">
                  <label htmlFor="city" className="form-label">
                    Город
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      className=""
                      id="city"
                      {...register("city")}
                    />
                  </div>
                </div>
                <div className="col-7 my-1">
                  <label htmlFor="userFIO" className="form-label">
                    Фамилия Имя Отчество
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      className=""
                      id="userFIO"
                      {...register("fullName")}
                    />
                  </div>
                </div>
                <div className="col-5 my-1">
                  <label htmlFor="dateAccept" className="form-label">
                    Дата решения аттес. комиссии
                  </label>
                  <FormControl type="date" id="dateAccept" {...register("dateAccept")} />
                </div>

                <div className="col-12 my-1">
                  <label htmlFor="course" className="form-label">
                    Присвоена квалификация
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      className="num"
                      id="course"
                      {...register("course")}
                    />
                  </div>
                </div>
                <div className="col-12 my-1">
                  <label htmlFor="course" className="form-label">
                    Код услуги
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      className="num"
                      id="course"
                      {...register("course")}
                    />
                  </div>
                </div>
                <div className="col-12 my-1">
                  <label htmlFor="namePredsedatel" className="form-label">
                    Председатель
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      id="namePredsedatel"
                      {...register("namePredsedatel")}
                    />
                  </div>
                </div>

                <div className="col-12 my-1">
                  <label htmlFor="name_Chel_com" className="form-label">
                    Руководитель
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      id="name_Chel_com"
                      {...register("name_Chel_com")}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
