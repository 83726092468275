import React from "react";
import {Link} from "react-router-dom";

export default function Path404(props) {
  return (
    <div>
      <h2>Упс, тут ничего! 404</h2>
      <p>
        <Link className={"btn btn-outline-secondary"} to="/">
          Вернуться на главную
        </Link>
      </p>
    </div>
  );
}
