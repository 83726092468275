import React from "react";
import {useParams, useSearchParams} from "react-router-dom";

export default function GetParm() {
  let params = useParams();
  console.log(params);
  console.log(useSearchParams("testtt"));
  return <h2>Invoice: {params.test} и</h2>;
  // return <><h2>User</h2>
  //
  //     {<TableOfUsers users={props?.users}/>}
  //     {props?.users?.map((val,key) => {
  //         {console.log(val)}
  //         return <div>{val.groupName}</div>
  //     })}
  //
  // </>;
}
