import React from "react";
import PrivateRoute from "../pages/auth/PrivateRoute";
import ProfilePage from "../pages/auth/ProfilePage";

export function RouteProfile() {
  return (
    <PrivateRoute>
      <ProfilePage />
    </PrivateRoute>
  );
}
