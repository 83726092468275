import CONSTANTS from "../../Constants";

export default function GeneratorURL({
  urlBase,
  urlApi,
  addCenter = true,
  urlCenter,
  centerId = 16,
  urlEntity,
  urlItem,
  itemId,
  page,
  perPage,
}) {
  function addSlashOrEmpty(arg) {
    return arg ? arg + "/" : "";
  }

  const base = urlBase ? urlBase : CONSTANTS.URL.BASE;
  const api = addSlashOrEmpty(urlApi ? urlApi : CONSTANTS.URL.API);

  const center = addCenter
    ? addSlashOrEmpty(urlCenter ? urlCenter : CONSTANTS.URL.CENTER)
    : "";
  const currentCenterId = centerId ? addSlashOrEmpty(centerId) : "";

  const item = itemId ? itemId : "";
  const entity = item ? addSlashOrEmpty(urlEntity) : urlEntity;

  const addUrlItem = urlItem ? urlItem : "";
  const addItemSlesh = addUrlItem ? addSlashOrEmpty(item) : item;

  const urlPage = page && perPage ? `?per_page=${perPage}&page=${page}` : "";
  // console.log('URL', base + api + center + currentCenterId + entity + item + urlPage)
  return (
    base + api + center + currentCenterId + entity + addItemSlesh + addUrlItem + urlPage
  );
}
