import React from "react";
import "./Template.css";

export default function TemplateDiplomaPlus2(data) {
  // console.log("TemplateDiplomaPlus2", data)

  function Disciplines(items) {
    const html = items?.map((val, index) => {
      return (
        "        <tr>\n" +
        '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
        '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">' +
        val.title +
        "</span>\n" +
        "            </p></td>\n" +
        '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
        '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
        "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
        '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
        '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
        "                    style=\"font-family:'Times New Roman'\">" +
        val.hours +
        "</span></p></td>\n" +
        '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
        '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span style="font-family:\'Times New Roman\'">' +
        val.score +
        "</span>\n" +
        "            </p></td>\n" +
        "        </tr>\n"
      );
    });
    return html?.join("");
  }

  return (
    "<html>\n" +
    "<head>\n" +
    '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>\n' +
    '    <meta http-equiv="Content-Style-Type" content="text/css"/>\n' +
    '    <meta name="generator" content="Aspose.Words for .NET 21.12.0"/>\n' +
    "    <title></title>\n" +
    '    <style type="text/css">body {\n' +
    "        line-height: 108%;\n" +
    "        font-family: Calibri;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    p {\n" +
    "        margin: 0pt 0pt 8pt\n" +
    "    }\n" +
    "\n" +
    "    table {\n" +
    "        margin-top: 0pt;\n" +
    "        margin-bottom: 8pt\n" +
    "    }\n" +
    "\n" +
    "    .Footer {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    .Header {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    " @media print {\n" +
    "    .rotatePrint {\n" +
    "        margin-top: 200px;\n" +
    "        transform: rotate(90deg);\n" +
    "    }\n" +
    "    .diplomaBack {\n" +
    "        padding-top: 125px;\n" +
    "        padding-left: 30px;\n" +
    "    }\n" +
    "} .TableGrid {\n" +
    "    }</style>\n" +
    "</head>\n" +
    "<body>\n" +
    '<div class="templateDiplomaPlusBack diplomaBack">\n' +
    '    <table cellspacing="0" cellpadding="0" class="TableGrid"\n' +
    '           style="width:503.25pt; margin-left:14.2pt; margin-bottom:0pt; border-collapse:collapse">\n' +
    Disciplines(data?.disciplines) +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">6</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">12</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">6</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">12</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">6</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">12</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">6</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">12</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:293.95pt; padding:4.25pt 5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">Тест Тест Тест Тест Тест Тест Тест Тест Тест</span>\n' +
    "            </p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    '            <td style="width:60.1pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">6</span></p></td>\n" +
    '            <td style="width:53pt; padding:4.25pt 5.4pt; vertical-align:middle"><p\n' +
    '                    style="margin-bottom:0pt; font-size:14pt"><span style="font-family:\'Times New Roman\'">зачтено</span>\n' +
    "            </p></td>\n" +
    "        </tr>\n" +
    "    </table>\n" +
    '    <p style="margin-left:389.85pt; margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-left:389.85pt; margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-left:389.85pt; margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-left:389.85pt; margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-left:389.85pt; margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt;margin-left:20pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">" +
    data?.additional +
    "</span></p>\n" +
    '    <p style="margin-left:389.85pt; margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-left:389.85pt; margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-left:389.85pt; margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-left:389.85pt; margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">" +
    data?.name_Supervisor +
    "</span></p>\n" +
    '    <p style="margin-left:389.85pt; margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-left:389.85pt; margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">" +
    data?.nameSecretar +
    "</span></p>\n" +
    "</div>\n" +
    "</body>\n" +
    "</html>"
  );
}
