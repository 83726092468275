import React, {useEffect} from "react";
import {Card, Col, FormControl} from "react-bootstrap";
import {useForm} from "react-hook-form";
import DateCreateFromParser from "../../components/utils/dateCreateFromParser";
import dateFormat from "../../components/utils/dateFormat";

export default function FormDiplomaPlus({
  defaultData,
  index,
  templateData,
  setTemplateData,
}) {
  let newData = defaultData;

  // console.log('newData', newData)

  function findStaff(search, typeId) {
    let searchData = search?.staff?.find((x) => x.pivot.type === typeId);
    return searchData ? searchData.fullName : "";
  }

  newData = {
    ...newData,
    dateAccept: dateFormat(newData?.created_at ? newData?.created_at : new Date()),
    date_to: dateFormat(newData?.created_at ? newData?.created_at : new Date()),
    date_from: dateFormat(newData?.created_at ? newData?.created_at : new Date()),
    date: dateFormat(newData?.created_at ? newData?.created_at : new Date()),
    namePredsedatel: findStaff(newData, 1),
    name_Chel_com: findStaff(newData, 2),
    nameSecretar: findStaff(newData, 3),
  };

  findStaff(newData, 5);
  const {register, handleSubmit, watch} = useForm({defaultValues: newData});

  const onSubmit = (data) => console.log(data);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      const NewData = value;

      NewData.date = DateCreateFromParser(NewData.date);

      const NewTemplateData = templateData;
      NewTemplateData[index] = NewData;
      setTemplateData([...NewTemplateData]);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Col md={"5"}>
        <h3>Приложения к диплому (Титульник)</h3>
        <Card>
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                {/*<div className="col-12 my-1">*/}
                {/*  <label htmlFor="aboutMe" className="form-label">Организация</label>*/}
                {/*  <div className="input-group has-validation">*/}
                {/*    <FormControl type="text" className="num" id="aboutMe" {...register("aboutMe")}/>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className="col-6 my-1">
                  <label htmlFor="regNum" className="form-label">
                    № регистрационный номер
                  </label>
                  <FormControl
                    type="number"
                    min={0}
                    id="regNum"
                    {...register("regNum")}
                  />
                </div>

                <div className="col-12 my-1">
                  <label htmlFor="userFIO" className="form-label">
                    Фамилия Имя Отчество
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      className=""
                      id="userFIO"
                      {...register("students.fullName")}
                    />
                  </div>
                </div>

                <div className="col-6 my-1">
                  <label htmlFor="date_from" className="form-label">
                    Период обучения С
                  </label>
                  <FormControl type="date" id="date_from" {...register("date_from")} />
                </div>

                <div className="col-6 my-1">
                  <label htmlFor="date_to" className="form-label">
                    Период обучения По
                  </label>
                  <FormControl type="date" id="date_to" {...register("date_to")} />
                </div>
                <div className="col-12 my-1">
                  <label htmlFor="pastEduction" className="form-label">
                    Предыдущий документ об образовании
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      className="num"
                      id="pastEduction"
                      {...register("students.pastEduction")}
                    />
                  </div>
                </div>

                <div className="col-6 my-1">
                  <label htmlFor="dateAccept" className="form-label">
                    Дата решения аттестационной комиссии
                  </label>
                  <FormControl type="date" id="dateAccept" {...register("dateAccept")} />
                </div>

                <div className="col-12 my-1">
                  <label htmlFor="spec" className="form-label">
                    Профессия
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="text"
                      className="num"
                      id="spec"
                      {...register("course.title")}
                    />
                  </div>
                </div>
                <div className="col-12 my-1">
                  <label htmlFor="hours" className="form-label">
                    Часов
                  </label>
                  <div className="input-group has-validation">
                    <FormControl
                      type="number"
                      min={1}
                      className="num"
                      id="hours"
                      {...register("course.hours")}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
