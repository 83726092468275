export default function DateCreateFromParser(date) {
  if (!date) {
    return {
      dd: 99,
      mm: 99,
      yyyy: 99,
      MM: "Выберите дату",
    };
  }
  if (typeof date === "object") {
    return date;
  }
  let NewDate = date.split("-");

  const mm = {
    "01": "января",
    "02": "февраля",
    "03": "марта",
    "04": "апреля",
    "05": "мая",
    "06": "июня",
    "07": "июля",
    "08": "августа",
    "09": "сентября",
    10: "октября",
    11: "ноября",
    12: "декабря",
  };
  return {
    dd: NewDate[2],
    mm: NewDate[1],
    yyyy: NewDate[0],
    MM: mm[NewDate[1]],
    date,
  };
}
