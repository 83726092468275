import React, {useEffect} from "react";
import {Alert, Card, Col, FormControl} from "react-bootstrap";
import {useForm} from "react-hook-form";
import DateCreateFromParser from "../../components/utils/dateCreateFromParser";

export default function FormAnketa({defaultData, index, templateData, setTemplateData}) {
  // console.log('newData', newData)

  const {register, handleSubmit, watch} = useForm({defaultValues: defaultData});

  const onSubmit = (data) => console.log(data);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      const NewData = value;

      NewData.date = DateCreateFromParser(NewData.date);

      const NewTemplateData = templateData;
      NewTemplateData[index] = NewData;
      setTemplateData([...NewTemplateData]);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Col md={"5"}>
        <h3>Форма анкеты</h3>
        <Card>
          <Card.Body>
            <form onSubmit={handleSubmit(onSubmit)} className={"mb-3"}>
              <div className="row g-3">
                <div className="col-4">
                  <label htmlFor="course" className="form-label">
                    Фамилия
                  </label>
                  <FormControl
                    type="text"
                    className=""
                    id="course"
                    {...register("surname")}
                  />
                </div>

                <div className="col-4">
                  <label htmlFor="course" className="form-label">
                    Имя
                  </label>
                  <FormControl
                    type="text"
                    className=""
                    id="course"
                    {...register("name")}
                  />
                </div>

                <div className="col-4">
                  <label htmlFor="course" className="form-label">
                    Отчество
                  </label>
                  <FormControl
                    type="text"
                    className=""
                    id="course"
                    {...register("patronymic")}
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="course" className="form-label">
                    ФИО
                  </label>
                  <FormControl
                    type="text"
                    readOnly={1}
                    id="course"
                    {...register("fullName")}
                  />
                </div>
              </div>
            </form>
            <Alert key={"alert-" + 1} variant={"info"}>
              Остальные поля заполняются вручную студентом.
            </Alert>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
