import React, {useState} from "react";
import {Alert} from "react-bootstrap";

export default function AlertDismissible({
  title = "",
  msg = "",
  variant = "",
  dismissible = true,
}) {
  const [show, setShow] = useState(true);

  function ShowMsg() {
    if (msg) {
      return <div>{msg}</div>;
    }
    return "";
  }

  function ShowTitle() {
    if (title) {
      return <Alert.Heading>{title}</Alert.Heading>;
    }
    return "";
  }

  if (show) {
    return (
      <Alert variant={variant} onClose={() => setShow(false)} dismissible={dismissible}>
        <ShowTitle />
        <ShowMsg />
      </Alert>
    );
  }
  // return <Button onClick={() => setShow(true)}>Show Alert</Button>;
  return "";
}
