import React from "react";
import {Button} from "react-bootstrap";
import {PrintElem} from "./PrintElem";

export function PrintAll({Template, templateData}) {
  if (templateData?.length > 1 || Template.length > 1) {
    return (
      <>
        <Button onClick={() => PrintElem("contentAll")}>Печать всех документов</Button>
        <div id={"contentAll"} style={{display: "none"}}>
          {templateData
            ?.map((val, index) => {
              //Привет костыль, как твои дела?
              return (
                <>
                  {Template.map((TemplateOne) => (
                    <div
                      className="wrapperPrint body2"
                      key={index}
                      dangerouslySetInnerHTML={{__html: TemplateOne(val)}}
                    />
                  )).reduce((prev, curr) => [
                    prev,
                    <>
                      <div className={"page-break"} key={"brake" + curr.key} />
                    </>,
                    curr,
                  ])}
                </>
              );
            })
            .reduce((prev, curr) => [
              prev,
              <>
                <div className={"page-break"} key={"brake" + curr.key} />
              </>,
              curr,
            ])}
        </div>
      </>
    );
  }
  return "";
}
