import React from "react";
import "./Template.css";

export default function TemplateSvid(data) {
  // console.log('TemplateSvid', data)
  return (
    "<html>\n" +
    "<head>\n" +
    '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>\n' +
    '    <meta http-equiv="Content-Style-Type" content="text/css"/>\n' +
    '    <meta name="generator" content="Aspose.Words for .NET 21.12.0"/>\n' +
    "    <title></title>\n" +
    '    <style type="text/css">.body2 {\n' +
    "        line-height: 108%;\n" +
    "        font-family: Calibri;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    p {\n" +
    "        margin: 0pt 0pt 8pt\n" +
    "    }\n" +
    "\n" +
    "    table {\n" +
    "        margin-top: 0pt;\n" +
    "        margin-bottom: 8pt\n" +
    "    }\n" +
    "\n" +
    "    .BodyText {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        widows: 0;\n" +
    "        orphans: 0;\n" +
    "        font-family: 'Times New Roman';\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    .Footer {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    .Header {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    span.a1 {\n" +
    "        font-family: 'Times New Roman'\n" +
    "    }\n" +
    "@media print {\n" +
    "    .rotatePrint {\n" +
    "        transform: rotate(90deg);\n" +
    "    }\n" +
    "    .TemplateSvid {\n" +
    "        position: relative; \n" +
    "left: 3cm;\n" +
    "top: 5cm;\n" +
    "    }" +
    "    body {\n" +
    "        margin: 0; \n" +
    "    }" +
    "}" +
    "\n" +
    ".page-break {page-break-after: always; }" +
    "    .TableGrid {\n" +
    "    }</style>\n" +
    "</head>\n" +
    '<div class="TemplateSvid rotatePrint">\n' +
    '    <div style="-aw-headerfooter-type:header-primary; clear:both"><p class="Header"><span style="-aw-import:ignore">&#xa0;</span>\n' +
    "    </p></div>\n" +
    '    <table cellspacing="0" cellpadding="0" class="TableGrid" style="margin-bottom:0pt; border-collapse:collapse">\n' +
    "        <tr>\n" +
    '            <td rowspan="4" style="width:244.4pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                    style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="font-size:12pt"><span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span>Общество</span><span\n' +
    '                        style="letter-spacing:-0.1pt"> </span><span>с</span><span style="letter-spacing:-0.1pt"> </span><span>ограниченной</span><span\n' +
    '                        style="letter-spacing:-0.1pt"> </span><span>ответственностью</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span>"Первый Профессиональный Институт Эстетики"</span>\n' +
    "                </p>\n" +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="font-size:12pt"><span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="font-size:12pt"><span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="font-size:12pt"><span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="font-size:12pt"><span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span>13131</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span>01 октября 2021 года</span></p>\n' +
    '                <p class="BodyText" style="font-size:12pt"><span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span>Москва</span></p></td>\n' +
    '            <td style="width:237.25pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p class="BodyText"\n' +
    '                                                                                                       style="font-size:12pt">\n' +
    '                <span style="font-weight:bold; -aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold; font-style:italic\">" +
    data.name +
    "</span><span\n" +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold; font-style:italic; letter-spacing:0.05pt\"> </span>\n" +
    "                </p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:14pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold; font-style:italic\">Иван</span><span\n" +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold; font-style:italic; letter-spacing:-0.65pt\"> </span><span\n" +
    "                        style=\"font-family:'Times New Roman'; font-weight:bold; font-style:italic\">Иванович</span></p>\n" +
    '                <p class="BodyText" style="font-size:12pt"><span\n' +
    '                        style="font-weight:bold; color:#ffffff; -aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="font-size:12pt"><span\n' +
    '                        style="font-weight:bold; color:#ffffff; -aw-import:ignore">&#xa0;</span></p></td>\n' +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:237.25pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p class="BodyText"\n' +
    '                                                                                                       style="text-align:center; font-size:12pt">\n' +
    '                <span style="font-weight:bold">по</span><span\n' +
    '                    style="font-weight:bold; letter-spacing:-0.05pt"> </span><span style="font-weight:bold">должности служащего</span>\n' +
    "            </p>\n" +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span>"Специалист по представлению визажных услуг" в объёме 144 часа</span>\n' +
    "                </p>\n" +
    '                <p class="BodyText" style="font-size:12pt"><span style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt">\n' +
    "                    <span>Решением аттестационной комиссии</span></p>\n" +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="letter-spacing:-2.6pt; -aw-import:spaces">&#xa0;</span><span>от 01 октября 2021 года</span><span\n' +
    '                        style="letter-spacing:0.05pt">, протокол № 3/11</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="letter-spacing:0.05pt; -aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="font-weight:bold">Присвоена</span><span\n' +
    '                        style="font-weight:bold; letter-spacing:-0.05pt"> </span><span style="font-weight:bold">квалификация</span>\n' +
    "                </p>\n" +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span>Мастер по наращиванию ресниц, 5 категории (разряда)</span>\n' +
    "                </p>\n" +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span>код услуги – Ф/01.4-А/02.4, В/01.5.-В/04.5</span>\n' +
    "                </p>\n" +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p>\n' +
    '                <p class="BodyText" style="text-align:center; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p></td>\n' +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:237.25pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:right"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">Иванов А. А.</span></p>\n" +
    '                <p class="BodyText" style="text-align:right; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p></td>\n' +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:237.25pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:right"><span\n' +
    "                    style=\"font-family:'Times New Roman'\">Петров</span><span\n" +
    "                    style=\"font-family:'Times New Roman'; letter-spacing:-0.05pt\"> </span><span\n" +
    "                    style=\"font-family:'Times New Roman'\">Б. Б.</span></p>\n" +
    '                <p class="BodyText" style="text-align:right; font-size:12pt"><span\n' +
    '                        style="-aw-import:ignore">&#xa0;</span></p></td>\n' +
    "        </tr>\n" +
    "    </table>\n" +
    '    <p style="line-height:108%; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <div style="-aw-headerfooter-type:footer-primary; clear:both"><p class="Footer"><span style="-aw-import:ignore">&#xa0;</span>\n' +
    "    </p></div>\n" +
    "</div>\n" +
    "</html>"
  );
}
