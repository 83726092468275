import React from "react";
import {Dropdown, DropdownButton} from "react-bootstrap";
import {BsEye, BsPrinter, BsPrinterFill} from "react-icons/bs";
import {Link} from "react-router-dom";

export const ColumnsGroupsAndUsers = [
  // {
  //     // Build our expander column
  //     id: 'expander', // Make sure it has an ID
  //     Header: ({getToggleAllRowsExpandedProps, isAllRowsExpanded}) => (
  //         <span {...getToggleAllRowsExpandedProps()}>
  //         {isAllRowsExpanded ? '👇' : '👉'}
  //       </span>
  //     ),
  //     accessor: 'groupId',
  // },
  {
    Header: "Группа",
    columns: [
      {
        Header: "Имя",
        accessor: "title",
        Cell: ({row}) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? "👇" : "👉"}
              {row.original.title} ({row.original.students.length})
            </span>
          ) : row.original.title ? (
            <>🚫{row.original.title}</>
          ) : (
            <>👤{row.original.fullName}</>
          ),
      },
    ],
  },
  {
    Header: "Курс",
    accessor: "course",
    Cell: ({row}) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand || row.original.title ? (
        <>{row.original.course?.title}</>
      ) : (
        <>{row.original.course?.title}</>
      ),
  },
  {
    Header: "Кнопки",
    accessor: "age",
    Cell: ({row}) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand || row.original.title ? (
        <DropdownButton
          variant="primary"
          title="Действия"
          id="input-group-dropdown-2"
          align="end"
        >
          {/*<Dropdown.ItemText>Действия для групп</Dropdown.ItemText>*/}
          {/*<Dropdown.Divider/>*/}
          <Dropdown.Item
            as={Link}
            to={{
              pathname: "/all/group/" + row.original.id,
              // search: "?utm=your+face"
            }}
            state={{from: "the-page-id"}}
          >
            <BsPrinterFill /> Печать всех документов
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={{
              pathname: "/protokol/group/" + row.original.id,
            }}
            state={{from: "the-page-id"}}
          >
            <BsPrinter /> Печать протокола
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={{
              pathname: "/veditog/group/" + row.original.id,
            }}
            state={{from: "the-page-id"}}
          >
            <BsPrinter /> Вед. Итог. Аттестация
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={{
              pathname: "/diploma/group/" + row.original.id,
            }}
            state={{from: "the-page-id"}}
          >
            <BsPrinter /> Дипломы
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={{
              pathname: "/svid/group/" + row.original.id,
            }}
            state={{from: "the-page-id"}}
          >
            <BsPrinter /> Свидетельства
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={{
              pathname: "/anketa/group/" + row.original.id,
            }}
            state={{from: "the-page-id"}}
          >
            <BsPrinter /> Анкета
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={{
              pathname: "/prikaz/group/" + row.original.id,
            }}
            state={{from: "the-page-id"}}
          >
            <BsPrinter /> Приказ
          </Dropdown.Item>
          {/*<Dropdown.Item href="#">Another action</Dropdown.Item>*/}
          {/*<Dropdown.Item href="#">Something else here</Dropdown.Item>*/}
          {/*<Dropdown.Divider />*/}
          {/*<Dropdown.Item href="#">Separated link</Dropdown.Item>*/}
          <Dropdown.Divider />
          <Dropdown.Item href={"/group/" + row.original.id}>
            <BsEye /> Открыть карточку группы
          </Dropdown.Item>
        </DropdownButton>
      ) : (
        <DropdownButton
          variant="outline-secondary"
          title="Действия"
          id="input-group-dropdown-2"
          align="end"
        >
          {/*<Dropdown.ItemText>Действия для слушателя</Dropdown.ItemText>*/}
          {/*<Dropdown.Divider/>*/}
          <Dropdown.Item as={Link} to="/get/id=123">
            <BsPrinterFill /> Печать всех документов
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={{
              pathname: "/protokol/user/" + row.original.id,
            }}
            state={{from: "the-page-id"}}
          >
            <BsPrinter /> Диплом
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={{
              pathname: "/protokol/user/" + row.original.id,
            }}
            state={{from: "the-page-id"}}
          >
            <BsPrinter /> Свидетельство
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={{
              pathname: "/protokol/user/" + row.original.id,
            }}
            state={{from: "the-page-id"}}
          >
            <BsPrinter /> Анкета
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={{
              pathname: "/protokol/user/" + row.original.id,
            }}
            state={{from: "the-page-id"}}
          >
            <BsPrinter /> Приказ
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href={"/student/" + row.original.id}>
            <BsEye /> Открыть карточку слушателя
          </Dropdown.Item>
        </DropdownButton>
      ),
  },
];
