import React from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";

const RegisterPage = ({x}) => {
  let navigate = useNavigate();

  // let register = () => {
  //   auth().register(() => {
  //     // navigate({pathname: "/"})
  //   })
  // }

  return (
    <div className={"AuthContainer"}>
      <div className={"AuthBlock"}>
        <h2>Создайте аккаунт!</h2>
        <form>
          <fieldset>
            <legend>Регистрация (Ещё не работает)</legend>
            <ul>
              <li>
                <label htmlFor="username">Имя:</label>
                <input type="text" id="username" required />
              </li>
              <li>
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" required />
              </li>
              <li>
                <label htmlFor="password">Пароль:</label>
                <input type="password" id="password" required />
                <label htmlFor="password2">
                  Пароль: <br />
                  <small>ещё раз</small>
                </label>
                <input type="password" id="password2" required />
              </li>
            </ul>
          </fieldset>
          <Button
            variant={"success"}
            className={"btn btn-success text-dark disabled"}
            onClick={() => {}}
          >
            Зарегистрироваться
          </Button>
          <Button className={"text-dark"} onClick={() => navigate({pathname: "/login"})}>
            Есть аккаунт? Войти
          </Button>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
