import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import useAuthLogin from "../../../components/fetch/useAuthLogin";
import {useDispatch, useSelector} from "react-redux";
import {login} from "./authSlice";

export default function CheckCookieAndKey() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [APIkey, setAPIkey] = useState(null);
  const {data: getAuth} = useAuthLogin(APIkey);
  useEffect(() => {
    // console.log('searchParams', searchParams.get('key'))
    const key = searchParams.get("key");
    if (!APIkey && !auth?.isAuth && key) {
      // console.log('searchParams !!! ', searchParams.get('key'))
      setAPIkey({
        email: `${key}@${key}.com`,
        password: key,
      });
    }
    if (getAuth?.data) {
      // console.log('getAuth?.data', getAuth?.data)
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...getAuth.data,
        }),
      );

      dispatch(login(auth, getAuth.data));
    }
  }, [APIkey, auth, dispatch, getAuth, searchParams]);
  return "";
}
