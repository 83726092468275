import React, {useState} from "react";
import TableGroupsAndStudents from "../components/tables/TableGroupsAndUsers";
import Loading from "../components/Loading";
import AlertDismissible from "../components/AlertDismissible";
import useMasterGetFetch from "../components/fetch/useMasterGetFetch";
import CONSTANTS from "../Constants";

export default function GroupAndUsers() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const {
    data: groupsAndStudents,
    isLoading,
    error,
    isRefetching,
  } = useMasterGetFetch({urlEntity: CONSTANTS.URL.GET_GROUPS_AND_USERS});
  // console.log('groupsAndStudents', groupsAndStudents)

  const data = React.useMemo(() => groupsAndStudents?.data, [groupsAndStudents]);

  // console.log('data', groupsAndStudents)
  // console.log('isLoading', isLoading)
  // console.log('groupsAndStudents', groupsAndStudents)
  if (error) {
    return (
      <>
        Ошибка
        <pre>{error?.message}</pre>
      </>
    );
  } else if (isLoading) {
    return <Loading />;
  } else if (!groupsAndStudents?.data) {
    return <>Нет данных, может ошибка.</>;
  } else {
    return (
      <>
        <h2>
          Группы и слушатели <Loading conditional={isRefetching} />
        </h2>
        <AlertDismissible
          variant={"warning"}
          msg={"Нужно обновление этой страницы из-за изменений в базы данных"}
          show={true}
          dismissible={false}
        />
        {
          <TableGroupsAndStudents
            data={data}
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={page}
            perPage={perPage}
            totalPage={groupsAndStudents?.meta?.last_page}
            isRefetching={isRefetching}
          />
        }
      </>
    );
  }
}
