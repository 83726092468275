import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaFileArchive, FaScroll, FaShapes, FaTh, FaUsers} from "react-icons/fa";
import {BsPerson, BsPersonBadgeFill} from "react-icons/bs";

export default function Home() {
  return (
    <>
      <h2 className={"mb-2"}>Навигация</h2>
      <Row xs="auto" className={"g-4 py-2"}>
        <Col>
          <Card as={Link} to={"/group"} className={"text-center btn btn-outline-primary"}>
            <Card.Body>
              <Card.Title>
                Группы <FaUsers />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card
            as={Link}
            to={"/student"}
            className={"text-center btn btn-outline-primary"}
          >
            <Card.Body>
              <Card.Title>
                Студенты <BsPerson />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card as={Link} to={"/staff"} className={"text-center btn btn-outline-primary"}>
            <Card.Body>
              <Card.Title>
                Сотрудники <BsPersonBadgeFill />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card
            as={Link}
            to={"/course"}
            className={"text-center btn btn-outline-primary"}
          >
            <Card.Body>
              <Card.Title>
                Курсы <FaScroll />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card
            as={Link}
            to={"/courseCategory"}
            className={"text-center btn btn-outline-primary"}
          >
            <Card.Body>
              <Card.Title>
                Категории курсы <FaTh />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card
            as={Link}
            to={"/discipline"}
            className={"text-center btn btn-outline-primary"}
          >
            <Card.Body>
              <Card.Title>
                Дисциплины <FaShapes />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <h3 className={"mb-2"}>
        <Link to={"/"}>Документы</Link>
      </h3>
      <Row xs="auto" className={"g-4 py-2"}>
        <Col>
          <Card as={Link} to={"/"} className={"text-center btn btn-outline-warning"}>
            <Card.Body>
              <Card.Title>
                Протоколы <FaFileArchive />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card as={Link} to={"/"} className={"text-center btn btn-outline-warning"}>
            <Card.Body>
              <Card.Title>
                Итог. Ведомость <FaFileArchive />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card as={Link} to={"/"} className={"text-center btn btn-outline-danger"}>
            <Card.Body>
              <Card.Title>
                Дипломы <FaFileArchive />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card as={Link} to={"/"} className={"text-center btn btn-outline-danger"}>
            <Card.Body>
              <Card.Title>
                Свидетельства <FaFileArchive />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card as={Link} to={"/"} className={"text-center btn btn-outline-danger"}>
            <Card.Body>
              <Card.Title>
                Приказы <FaFileArchive />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card as={Link} to={"/"} className={"text-center btn btn-outline-danger"}>
            <Card.Body>
              <Card.Title>
                Анкеты <FaFileArchive />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <h3 className={"mb-2"}>
        <Link to={"/"}>Отчёты</Link>
      </h3>
      <Row xs="auto" className={"g-4 py-2"}>
        <Col>
          <Card as={Link} to={"/"} className={"text-center btn btn-outline-danger"}>
            <Card.Body>
              <Card.Title>
                ФИС ФРДО (ДПО)
                <FaFileArchive />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card as={Link} to={"/"} className={"text-center btn btn-outline-danger"}>
            <Card.Body>
              <Card.Title>
                ФИС ФРДО (ПО)
                <FaFileArchive />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card as={Link} to={"/"} className={"text-center btn btn-outline-danger"}>
            <Card.Body>
              <Card.Title>
                Очередь группы
                <FaFileArchive />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <h3 className={"my-2"}>Ручной генератор документов</h3>
      <Row xs="auto" className={"g-4 py-2"}>
        <Col>
          <Card
            as={Link}
            to={"/protokol"}
            className={"text-center btn btn-outline-warning"}
          >
            <Card.Body>
              <Card.Title>Протокол</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card as={Link} to={"/svid"} className={"text-center btn btn-outline-primary"}>
            <Card.Body>
              <Card.Title>Свидетельство о профессии</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card
            as={Link}
            to={"/diploma"}
            className={"text-center btn btn-outline-primary"}
          >
            <Card.Body>
              <Card.Title>Диплом</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card
            as={Link}
            to={"/veditog"}
            className={"text-center btn btn-outline-primary"}
          >
            <Card.Body>
              <Card.Title>Вед. Итог. Аттестация</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card
            as={Link}
            to={"/prikaz"}
            className={"text-center btn btn-outline-primary"}
          >
            <Card.Body>
              <Card.Title>Приказ</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card
            as={Link}
            to={"/anketa"}
            className={"text-center btn btn-outline-primary"}
          >
            <Card.Body>
              <Card.Title>Анкета</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <h6 className={"my-2"}>Легенда</h6>
      <Row xs={1} md={4} className="g-4">
        <Col>
          <Card border="primary">
            <Card.Body>
              <Card.Text>Синяя рамка - Работает</Card.Text>
              {/*<Card.Text>*/}
              {/*  В разработке*/}
              {/*</Card.Text>*/}
              {/*<Button variant="primary">Go somewhere</Button>*/}
            </Card.Body>
          </Card>
        </Col>{" "}
        <Col>
          <Card border="warning">
            <Card.Body>
              <Card.Text>Жёлтая рамка - в разработке</Card.Text>
              {/*<Card.Text>*/}
              {/*  В разработке*/}
              {/*</Card.Text>*/}
              {/*<Button variant="primary">Go somewhere</Button>*/}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card border="danger">
            <Card.Body>
              <Card.Text>Красная рамка - В планах (очередь)</Card.Text>
              {/*<Card.Text>*/}
              {/*  В разработке*/}
              {/*</Card.Text>*/}
              {/*<Button variant="primary">Go somewhere</Button>*/}
            </Card.Body>
          </Card>
        </Col>
        {/*  <Col>*/}
        {/*    <Card border="danger">*/}
        {/*      <Card.Body>*/}
        {/*        <Card.Title>Card Title</Card.Title>*/}
        {/*        <Card.Text>*/}
        {/*          Some quick example text to build on the card title and make up the bulk of*/}
        {/*          the card's content.*/}
        {/*        </Card.Text>*/}
        {/*        <Button variant="primary">Go somewhere</Button>*/}
        {/*      </Card.Body>*/}
        {/*    </Card>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        {/*<Row xs={1} md={3} className={"g-4 mt-auto"}>*/}
        {/*  <Col>*/}
        {/*    <Card bg={'light'} border="primary">*/}
        {/*      <Card.Body>*/}
        {/*        <Card.Title>Card Title</Card.Title>*/}
        {/*        <Card.Text>*/}
        {/*          Some quick example text to build on the card title and make up the bulk of*/}
        {/*          the card's content.*/}
        {/*        </Card.Text>*/}
        {/*        <Button variant="primary">Go somewhere</Button>*/}
        {/*      </Card.Body>*/}
        {/*    </Card>*/}
        {/*  </Col>*/}
        {/*  <Col>*/}
        {/*    <Card>*/}
        {/*      <Card.Body>*/}
        {/*        <Card.Title>Card Title</Card.Title>*/}
        {/*        <Card.Text>*/}
        {/*          Some quick example text to build on the card title and make up the bulk of*/}
        {/*          the card's content.*/}
        {/*        </Card.Text>*/}
        {/*        <Button variant="primary">Go somewhere</Button>*/}
        {/*      </Card.Body>*/}
        {/*    </Card>*/}
        {/*  </Col>*/}
      </Row>
    </>
  );
}
