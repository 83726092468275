import React from "react";
import {Alert, Col, Form, Stack} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {BsEye} from "react-icons/bs";

export default function DoubleSideLeft({
  dataStudentsGroupBy,
  selectLeftInput,
  setSelectLeftInput,
  radioLeftInput,
  setRadioLeftInput,
  setCheckRightInput,
}) {
  const {
    register,
    watch,
    reset,
    formState: {errors},
  } = useForm();

  React.useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      setRadioLeftInput(value.radioSideLeft);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Col md={6}>
        <Alert variant={"secondary"} className={"py-2 pt-2"}>
          <b>Выберите основного студента.</b> Данные студента будут перенесены из правой
          стороны в левую. Будут перенесены: его группы, курсы, оценки, контакты,
          образование, паспорт, старые имена и карбух ID.
        </Alert>
        <Form.Group className="mb-3" controlId="citizenship">
          <Form.Label>Список похожих студентов</Form.Label>
          <Form.Select
            onChange={(e) => {
              setSelectLeftInput(e.target.value);
              setRadioLeftInput(0);
              setCheckRightInput([]);
              reset();
            }}
            value={selectLeftInput}
          >
            {dataStudentsGroupBy.map((student, index) => {
              // console.log('student', student)
              return (
                <option value={index} key={"darkSide" + index}>
                  {student[0]?.namePlussurname} | Повторений: {student.length}
                </option>
              );
            })}
          </Form.Select>
          {/*<Form.Text className="text-muted">*/}
          {/*  Текст*/}
          {/*</Form.Text>*/}
        </Form.Group>
        <Form>
          {dataStudentsGroupBy[selectLeftInput].map((student, index) => {
            const type = "radio";
            return (
              <div key={`leftSide-${type}-${index}`} className="mb-3">
                <Stack direction="horizontal" gap={3}>
                  <div>
                    <Form.Check
                      type={type}
                      label={
                        <>
                          {student.fullName} (id:{" "}
                          <a
                            href={"/student/" + student.id}
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            {student.id}
                          </a>
                          )
                        </>
                      }
                      id={`leftSide-${type}-${index}`}
                      value={student.id}
                      {...register("radioSideLeft", {required: true})}
                    />
                  </div>
                  <div className="ms-auto">
                    {radioLeftInput == student.id ? <BsEye /> : ""}
                  </div>
                </Stack>
              </div>
            );
          })}
        </Form>
      </Col>
    </>
  );
}
