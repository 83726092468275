import React from "react";
import "./Template.css";

export default function TemplateDiplomaPlus(data) {
  console.log("TemplateDiplomaPlus", data);

  return (
    "<html>\n" +
    "<head>\n" +
    '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>\n' +
    '    <meta http-equiv="Content-Style-Type" content="text/css"/>\n' +
    '    <meta name="generator" content="Aspose.Words for .NET 21.12.0"/>\n' +
    "    <title></title>\n" +
    '    <style type="text/css">body {\n' +
    "        line-height: 108%;\n" +
    "        font-family: Calibri;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    p {\n" +
    "        margin: 0pt 0pt 8pt\n" +
    "    }\n" +
    "\n" +
    "    .Footer {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    .Header {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    .TableGrid {\n" +
    "    }</style>\n" +
    "</head>\n" +
    "<body>\n" +
    '<div class="templateDiplomaPlusFront">\n' +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    '            style="font-family:\'Times New Roman\'; -aw-import:ignore" class="d-none">&#xa0;</span></p>\n' +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">Общество с ограниченной ответственностью</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    '            style="font-family:\'Times New Roman\'">"Первый Профессиональный Институт Эстетики"</span></p>\n' +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    '            style="font-family:\'Times New Roman\'; -aw-import:ignore" class="d-none">&#xa0;</span></p>\n' +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    '            style="font-family:\'Times New Roman\'; -aw-import:ignore" class="d-none">&#xa0;</span></p>\n' +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">133451 123123</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:18pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold; font-style:italic\">Фамилия Имя Отчество</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">прошел(а) профессиональную переподготовку в</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    '            style="font-family:\'Times New Roman\'">ООО "Первый Профессиональный Институт Эстетики"</span></p>\n' +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">с 25.10.2020 г. По " +
    data.date_from.dd +
    "." +
    data.date_from.mm +
    "." +
    data.date_from.yyyy +
    " г.</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">по дополнительной профессиональной программе</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    '            style="font-family:\'Times New Roman\'">"Сестринское дело в косметологии"</span></p>\n' +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">Диплом о среднем профессиональном образовании 2017 год.</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">от 15 сентября 2020 года</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">удостоверяет право</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">на ведение профессиональной деятельности в сфере</span></p>\n" +
    '    <p style="margin-top:12pt; margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt"><span\n' +
    '            style="font-family:\'Times New Roman\'">"Сестринское дело в косметологии"</span></p>\n' +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:16pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:16pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:16pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:16pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:16pt" class="d-none"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:16pt" class="d-none"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:16pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:16pt"><span\n' +
    "            style=\"font-family:'Times New Roman'\">288 час.</span></p>\n" +
    "</div>\n" +
    "</body>\n" +
    "</html>"
  );
}
