import {combineReducers, createStore} from "@reduxjs/toolkit";
import authReducer from "../pages/auth/components/authSlice";

function getInit() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.name && user?.token
    ? {
        user: {
          name: user?.name,
          token: user?.token,
        },
        isAuth: true,
      }
    : {
        user: {
          name: "",
          token: "",
        },
        isAuth: false,
      };
}

const initAuth = getInit();

const rootReducer = combineReducers({auth: authReducer});

export default createStore(rootReducer, {
  auth: {
    ...initAuth,
  },
});
