import React from "react";
import {useParams} from "react-router-dom";
import Loading from "./Loading";
import useMasterGetFetch from "./fetch/useMasterGetFetch";

function WrapperFetchData(props) {
  const params = useParams();
  // console.log('params', params)
  const {id} = params;
  const itemId = id;
  // console.log('props.url', props.url)

  const master = useMasterGetFetch({
    name: "MasterFetch",
    itemId: id,
    urlEntity: props.url,
  });

  // console.log('master', master)

  function RenderChild() {
    const childrenWithProps = React.Children.map(props.children, (child) => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {master, urlEntity: props.url, itemId});
      }
      return child;
    });

    return (
      <React.Suspense
        fallback={
          <div className={"text-center"}>
            <Loading />
          </div>
        }
      >
        {childrenWithProps}
      </React.Suspense>
    );
  }

  // console.log('isLoading', isLoading)
  // console.log('isRefetching', isRefetching)
  // console.log('isFetched', isFetched)
  // console.log('master', master)
  if (master?.error) {
    console.log("master error", master?.error);
    return (
      <>
        Ошибка
        <pre>{master?.error?.message}</pre>
      </>
    );
  } else if (master?.isLoading && master?.isFetching) {
    return (
      <div className={"text-center"}>
        <Loading />
      </div>
    );
  } else if (master?.data?.data?.length === 0) {
    return <div>Не найдено.</div>;
  } else {
    return (
      <>
        <RenderChild />
      </>
    );
  }
}

export default WrapperFetchData;
