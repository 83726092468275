import React from "react";
import {Button, Card, Col, FormControl, InputGroup} from "react-bootstrap";
import {useFieldArray} from "react-hook-form";

export default function FormVeditog({props}) {
  const {group, groupIndex, control, register, getValues} = props;
  let newData = group;

  // console.log('newData', newData)

  function findStaff(search, typeId) {
    let searchData = search?.staff?.find((x) => x.pivot.type === typeId);
    return searchData ? searchData.fullName : "";
  }

  newData = {
    ...newData,
    namePredsedatel: findStaff(newData, 1),
    name_Chel_com: findStaff(newData, 2),
    nameSecretar: findStaff(newData, 3),
    commissionStaff: newData?.staff ? [...newData?.staff] : [],
  };

  findStaff(newData, 5);
  const {
    fields: studentsFields,
    append: studentsAppend,
    remove: studentsRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `groups[${groupIndex}].students`, // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });
  const {
    fields: commissionStaffFields,
    append: commissionStaffAppend,
    remove: commissionStaffRemove,
  } = useFieldArray({
    control,
    name: `groups[${groupIndex}].course.commissionStaff`,
  });

  return (
    <>
      <Col md={"5"}>
        <h3>Форма создания документа</h3>
        <Card>
          <Card.Body>
            <div className="row g-3">
              {/*<div className="col-4 my-1">*/}
              {/*  <label htmlFor="num1" className="form-label">Номер</label>*/}
              {/*  <FormControl type="text" id="num1" {...register("num1")}/>*/}
              {/*</div>*/}

              <div className="col-12">
                <label htmlFor="course" className="form-label">
                  Курс программы
                </label>
                <FormControl
                  type="text"
                  className=""
                  id="course"
                  {...register(`groups[${groupIndex}].course.title`)}
                />
              </div>

              <div className="col-8 my-1">
                <label htmlFor="date" className="form-label">
                  Дата
                </label>
                <FormControl type="date" id="date" {...register("date")} />
              </div>

              <div className="col-12">
                <label htmlFor="namePredsedatel" className="form-label">
                  Председатель
                </label>
                <div className="input-group has-validation">
                  <FormControl
                    type="text"
                    id="namePredsedatel"
                    {...register(`groups[${groupIndex}].namePredsedatel`)}
                  />
                </div>
              </div>

              <div className="col-12">
                <label htmlFor="name_Chel_com" className="form-label">
                  Члены комиссии{" "}
                  <Button
                    onClick={() => commissionStaffAppend({name: ""})}
                    size={"sm"}
                    variant={"success"}
                  >
                    +
                  </Button>
                </label>

                {commissionStaffFields?.map((field, index) => (
                  <InputGroup key={"commissionStaff-" + index}>
                    <FormControl
                      type="text"
                      id="nameSecretar"
                      defaultValue={field.fullName}
                      {...register(
                        `groups[${groupIndex}].commissionStaff.${index}.fullName`,
                      )}
                    />
                    <Button
                      onClick={() => commissionStaffRemove(index)}
                      variant={"warning"}
                    >
                      -
                    </Button>
                  </InputGroup>
                ))}
              </div>

              <div className="col-12">
                <label htmlFor="nameSecretar" className="form-label">
                  Секретарь
                </label>
                <div className="input-group has-validation">
                  <FormControl
                    type="text"
                    id="nameSecretar"
                    {...register(`groups[${groupIndex}].nameSecretar`)}
                  />
                </div>
              </div>
              <div className="col-3 text-center">
                <label htmlFor="nameSecretar" className="form-label">
                  " 5 " - Отлч.
                </label>
                <div className="input-group has-validation">
                  <FormControl
                    type="text"
                    id="nameSecretar"
                    {...register(`groups[${groupIndex}].itog5`)}
                  />
                </div>
              </div>
              <div className="col-3 text-center">
                <label htmlFor="nameSecretar" className="form-label">
                  " 4 " - Хорш.
                </label>
                <div className="input-group has-validation">
                  <FormControl
                    type="text"
                    id="nameSecretar"
                    {...register(`groups[${groupIndex}].itog4`)}
                  />
                </div>
              </div>
              <div className="col-3 text-center">
                <label htmlFor="nameSecretar" className="form-label">
                  " 3 " - Удвл.
                </label>
                <div className="input-group has-validation">
                  <FormControl
                    type="text"
                    id="nameSecretar"
                    {...register(`groups[${groupIndex}].itog3`)}
                  />
                </div>
              </div>
              <div className="col-3 text-center">
                <label htmlFor="nameSecretar" className="form-label">
                  " 2 " - Неуд.
                </label>
                <div className="input-group has-validation">
                  <FormControl
                    type="text"
                    id="nameSecretar"
                    {...register(`groups[${groupIndex}].itog2`)}
                  />
                </div>
              </div>

              <h4>
                Слушатели:{" "}
                <Button
                  onClick={() => studentsAppend({name: ""})}
                  size={"sm"}
                  variant={"success"}
                >
                  +
                </Button>
              </h4>
              <div className="col-12">
                {studentsFields?.map((field, index) => (
                  <div className={" mb-3"} key={index + "box"}>
                    <InputGroup>
                      <InputGroup.Text>ФИО</InputGroup.Text>
                      <FormControl
                        type="text"
                        key={field.id + "fullName"} // important to include key with field's id
                        {...register(`students.${index}.fullName`)}
                      />
                      <Button onClick={() => studentsRemove(index)} variant={"warning"}>
                        -
                      </Button>
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text>Тестирование: % и оценка</InputGroup.Text>
                      <FormControl
                        aria-label="First name"
                        key={field.id + "testPr"}
                        {...register(`students.${index}.testPr`)}
                      />
                      <FormControl
                        aria-label="Last name"
                        key={field.id + "testBall"}
                        {...register(`students.${index}.testBall`)}
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text>Собеседование: № билета и оценка</InputGroup.Text>
                      <FormControl
                        aria-label="First name"
                        key={field.id + "sobBilet"}
                        {...register(`students.${index}.sobBilet`)}
                      />
                      <FormControl
                        aria-label="Last name"
                        key={field.id + "sobBall"}
                        {...register(`students.${index}.sobBall`)}
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text>Практ. навыки и общая оценка</InputGroup.Text>
                      <FormControl
                        aria-label="First name"
                        key={field.id + "sobBilet"}
                        {...register(`students.${index}.praktBall`)}
                      />
                      <FormControl
                        aria-label="Last name"
                        className={"border border-1 border-primary"}
                        key={field.id + "sobBall"}
                        {...register(`students.${index}.endBall`)}
                      />
                    </InputGroup>
                  </div>
                ))}
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
