import React from "react";
import "./Template.css";

export default function TemplateProtokol(data) {
  console.log("TemplateProtokol", data);

  function UsersFor(users) {
    const html = users?.map((val, index) => {
      return (
        "        <tr>\n" +
        '            <td style="width:26.15pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        (index + 1) +
        "</span></p>\n" +
        "            </td>\n" +
        '            <td style="width:180.55pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt"><span style="font-family:\'Times New Roman\'">' +
        val?.fullName +
        "</span></p></td>\n" +
        '            <td style="width:24.65pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        val?.testPr +
        "</span>\n" +
        "                </p></td>\n" +
        '            <td style="width:31.7pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        val?.testBall +
        "</span>\n" +
        "                </p></td>\n" +
        '            <td style="width:24.65pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        val?.sobBilet +
        "</span>\n" +
        "                </p></td>\n" +
        '            <td style="width:38.8pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        val?.sobBall +
        "</span></p>\n" +
        "            </td>\n" +
        '            <td style="width:60.1pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        val?.praktBall +
        "</span>\n" +
        "                </p></td>\n" +
        '            <td style="width:38.8pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        val?.endBall +
        "</span>\n" +
        "                </p></td>\n" +
        "        </tr>\n"
      );

      return (
        "        <tr>\n" +
        '            <td style="width:31.5pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        (index + 1) +
        "</span></p>\n" +
        "            </td>\n" +
        '            <td style="width:201.8pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span\n' +
        "                        style=\"font-family:'Times New Roman'\">" +
        val.fullName +
        "</span></p></td>\n" +
        '            <td style="width:17.55pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        val.testPr +
        "</span></p>\n" +
        "            </td>\n" +
        '            <td style="width:60.1pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        val.testBall +
        "</span>\n" +
        "                </p></td>\n" +
        '            <td style="width:45.9pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        val.sobBilet +
        "</span>\n" +
        "                </p></td>\n" +
        '            <td style="width:45.6pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        val.sobBall +
        "</span></p>\n" +
        "            </td>\n" +
        "        </tr>\n"
      );
    });
    return html?.join("");
  }

  function UsersForOne(users) {
    const html = users?.map((val, index) => {
      return (
        "            <tr>\n" +
        '                <td style="width:26.3pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                    <p style="margin-bottom:0pt; text-align:center"><span style="font-family:\'Times New Roman\'">' +
        (index + 1) +
        "</span>\n" +
        "                    </p></td>\n" +
        '                <td style="width:320.4pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">\n' +
        '                    <p style="margin-bottom:0pt"><span style="font-family:\'Times New Roman\'">' +
        val.fullName +
        "</span></p></td>\n" +
        "            </tr>\n"
      );
    });
    return html?.join("");
  }

  function mapName_Chel_com(users) {
    const html = users?.map((val, index) => {
      if (index === 0) {
        return (
          "        <tr>\n" +
          '            <td colspan="2" style="width:99.25pt; vertical-align:top"><p style="margin-bottom:4pt"><span\n' +
          "                    style=\"font-family:'Times New Roman'; font-weight:bold\">Члены комиссии:</span><span\n" +
          "                    style=\"font-family:'Times New Roman'\"> </span></p></td>\n" +
          '            <td colspan="2" style="width:329.6pt; vertical-align:top"><p style="margin-bottom:4pt"><span\n' +
          "                    style=\"font-family:'Times New Roman'; text-decoration:underline\">" +
          val.fullName +
          "</span></p></td>\n" +
          "        </tr>\n"
        );
      }
      return (
        "        <tr>\n" +
        '            <td colspan="2" style="width:99.25pt; vertical-align:top"><p style="margin-bottom:4pt"><span\n' +
        "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
        '            <td colspan="2" style="width:329.6pt; vertical-align:top"><p style="margin-bottom:4pt"><span\n' +
        "                    style=\"font-family:'Times New Roman'; text-decoration:underline\">" +
        val.fullName +
        "</span></p></td>\n" +
        "        </tr>\n"
      );
    });
    return html?.join("");
  }

  function mapName_Chel_comPodpis(users) {
    const html = users?.map((val, index) => {
      if (index === 0) {
        return (
          "        <tr>\n" +
          '            <td colspan="2" style="width:99.25pt; vertical-align:top"><p style="margin-bottom:0pt"><span\n' +
          "                    style=\"font-family:'Times New Roman'; font-weight:bold\">Члены комиссии:</span><span\n" +
          "                    style=\"font-family:'Times New Roman'\"> </span></p></td>\n" +
          '            <td colspan="2" style="width:329.6pt; vertical-align:top"><p style="margin-bottom:0pt"><span\n' +
          "                    style=\"font-family:'Times New Roman'; text-decoration:underline\">" +
          val.fullName +
          "</span></p></td>\n" +
          '            <td style="width:87.8pt; vertical-align:top"><p\n' +
          '                    style="margin-bottom:0pt; text-align:center; border-bottom-style:solid; border-bottom-width:0.75pt; padding-bottom:1pt">\n' +
          "                <span style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
          '                <p style="margin-bottom:0pt; text-align:center; font-size:8pt"><span\n' +
          "                        style=\"font-family:'Times New Roman'\">(подпись)</span></p></td>\n" +
          "        </tr>\n"
        );
      }
      return (
        "        <tr>\n" +
        '            <td colspan="2" style="width:99.25pt; vertical-align:top"><p style="margin-bottom:0pt"><span\n' +
        "                    style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
        '            <td colspan="2" style="width:329.6pt; vertical-align:top"><p style="margin-bottom:0pt"><span\n' +
        "                    style=\"font-family:'Times New Roman'; text-decoration:underline\">" +
        val.fullName +
        "</span></p></td>\n" +
        '            <td style="width:87.8pt; vertical-align:top"><p\n' +
        '                    style="margin-bottom:0pt; text-align:center; border-bottom-style:solid; border-bottom-width:0.75pt; padding-bottom:1pt">\n' +
        "                <span style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
        '                <p style="margin-bottom:0pt; text-align:center; font-size:8pt"><span\n' +
        "                        style=\"font-family:'Times New Roman'\">(подпись)</span></p></td>\n" +
        "        </tr>\n"
      );
    });
    return html?.join("");
  }

  // console.log('TEST', UsersFor(data?.users))
  return (
    "<html>\n" +
    "<head>\n" +
    '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>\n' +
    '    <meta http-equiv="Content-Style-Type" content="text/css"/>\n' +
    '    <meta name="generator" content="Aspose.Words for .NET 21.12.0"/>\n' +
    "    <title></title>\n" +
    '    <style type="text/css">body {\n' +
    "        line-height: 108%;\n" +
    "        font-family: Calibri;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    p {\n" +
    "        margin: 0pt 0pt 8pt\n" +
    "    }\n" +
    "\n" +
    "    li, table {\n" +
    "        margin-top: 0pt;\n" +
    "        margin-bottom: 8pt\n" +
    "    }\n" +
    "\n" +
    "    .Footer {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    .HTMLPreformatted {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-family: 'Courier New';\n" +
    "        font-size: 10pt\n" +
    "    }\n" +
    "\n" +
    "    .Header {\n" +
    "        margin-bottom: 0pt;\n" +
    "        line-height: normal;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    .ListParagraph {\n" +
    "        margin-left: 36pt;\n" +
    "        margin-bottom: 8pt;\n" +
    "        line-height: 108%;\n" +
    "        font-size: 11pt\n" +
    "    }\n" +
    "\n" +
    "    span.HTML {\n" +
    "        font-family: 'Courier New';\n" +
    "        font-size: 10pt\n" +
    "    }\n" +
    "\n" +
    "    .TableGrid {\n" +
    "    }</style>\n" +
    "</head>\n" +
    "<body>\n" +
    '<div><p style="text-align:center; line-height:normal; font-size:12pt"><span\n' +
    "        style=\"font-family:'Times New Roman'; font-weight:bold\">КНИГА ПРОТОКОЛОВ ИАК</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold\">ПРОТОКОЛ № " +
    data?.num1 +
    "/" +
    data?.num2 +
    "</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold\">заседания итоговой аттестационной комиссии (ИИК)</span>\n" +
    "    </p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold\">по приему итоговой аттестации</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold\">ПО ПРОГРАММЕ </span><br/><span\n" +
    "            style=\"font-family:'Times New Roman'; font-weight:bold\">ПРОФЕССИОНАЛЬНОЙ ПЕРЕПОДГОТОВКИ</span></p>\n" +
    '    <p style="margin-bottom:0pt; line-height:normal; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold\">Наименование программы:</span></p>\n" +
    '    <p style="margin-bottom:6pt; text-align:center; line-height:normal; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold; text-decoration:underline\">«" +
    data?.course?.title +
    "»</span></p>\n" +
    '    <p style="margin-bottom:6pt; text-align:right; line-height:normal; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold\">Дата </span><span\n" +
    "            style=\"font-family:'Times New Roman'; font-weight:bold; text-decoration:underline\">«" +
    data?.date?.dd +
    "» " +
    data?.date?.MM +
    " " +
    data?.date?.yyyy +
    ".</span>\n" +
    "    </p>\n" +
    '    <p style="margin-bottom:4pt; text-indent:35.4pt; line-height:normal; font-size:12pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold\">Присутствовали: </span></p>\n" +
    '    <table cellspacing="0" cellpadding="0" class="TableGrid"\n' +
    '           style="width:428.85pt; margin-bottom:0pt; border-collapse:collapse">\n' +
    "        <tr>\n" +
    '            <td colspan="3" style="width:109.9pt; vertical-align:top"><p style="margin-bottom:4pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; font-weight:bold\">Председатель ИАК:</span></p></td>\n" +
    '            <td style="width:318.95pt; vertical-align:top"><p style="margin-bottom:4pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; text-decoration:underline\">" +
    data?.namePredsedatel +
    "</span></p></td>\n" +
    "        </tr>\n" +
    mapName_Chel_com(data?.commissionStaff) +
    "        <tr>\n" +
    '            <td style="width:63.8pt; vertical-align:top"><p style="margin-bottom:6pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; font-weight:bold\">Секретарь:</span></p></td>\n" +
    '            <td colspan="3" style="width:365.05pt; vertical-align:top"><p style="margin-bottom:6pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; text-decoration:underline\">" +
    data?.nameSecretar +
    "</span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:0pt">\n' +
    '            <td style="width:63.8pt"></td>\n' +
    '            <td style="width:35.45pt"></td>\n' +
    '            <td style="width:10.65pt"></td>\n' +
    '            <td style="width:318.95pt"></td>\n' +
    "        </tr>\n" +
    "    </table>\n" +
    '    <ol type="1" style="margin:0pt; padding-left:0pt">\n' +
    '        <li class="ListParagraph"\n' +
    "            style=\"margin-left:32pt; line-height:108%; padding-left:4pt; font-family:'Times New Roman'; font-size:12pt\">\n" +
    "            <span>Проведение </span><span\n" +
    '                style="font-weight:bold">итоговой аттестационного экзамена</span><span> в виде </span><span\n' +
    '                style="text-decoration:underline">выполнения заданий в тестовой форме, собеседования и оценки практических навыков.</span>\n' +
    "        </li>\n" +
    "    </ol>\n" +
    '    <table cellspacing="0" cellpadding="0" class="TableGrid"\n' +
    '           style="width:512.55pt; margin-left:5.35pt; margin-bottom:0pt; border:0.75pt solid #000000; -aw-border:0.5pt single; -aw-border-insideh:0.5pt single #000000; -aw-border-insidev:0.5pt single #000000; border-collapse:collapse">\n' +
    "        <tr>\n" +
    '            <td rowspan="3"\n' +
    '                style="width:26.15pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">№ п/п</span></p></td>\n" +
    '            <td rowspan="3"\n' +
    '                style="width:180.55pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Фамилия, имя, отчество</span></p></td>\n" +
    '            <td colspan="2"\n' +
    '                style="width:67.15pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">I этап</span></p></td>\n" +
    '            <td colspan="3"\n' +
    '                style="width:145.15pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-right:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">II этап</span></p></td>\n" +
    '            <td rowspan="3"\n' +
    '                style="width:38.8pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Общая оценка</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td colspan="2"\n' +
    '                style="width:67.15pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Тестирование</span></p></td>\n" +
    '            <td colspan="2"\n' +
    '                style="width:74.25pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Собеседование</span></p></td>\n" +
    '            <td rowspan="2"\n' +
    '                style="width:60.1pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Оценка практических навыков</span></p></td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    '            <td style="width:24.65pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">%</span></p></td>\n" +
    '            <td style="width:31.7pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Оценка</span></p></td>\n" +
    '            <td style="width:24.65pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">№</span></p></td>\n" +
    '            <td style="width:38.8pt; border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border:0.5pt single">\n' +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">Оценка за ответ</span></p></td>\n" +
    "        </tr>\n" +
    UsersFor(data?.students) +
    "    </table>\n" +
    '    <p style="line-height:108%; font-size:1pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <ol start="2" type="1" style="margin:0pt; padding-left:0pt">\n' +
    '        <li class="ListParagraph"\n' +
    "            style=\"margin-left:32pt; line-height:108%; padding-left:4pt; font-family:'Times New Roman'; font-size:12pt\">\n" +
    '            <span style="font-weight:bold">Итоговая аттестационная комиссия</span><span> постановила выдать диплом о профессиональной переподготовке на ведение профессиональной деятельности в сфере </span><span\n' +
    '                style="font-weight:bold">«КУРС»</span><span> слушателям группы:</span></li>\n' +
    "    </ol>\n" +
    '    <div style="text-align:center">\n' +
    '        <table cellspacing="0" cellpadding="0" class="TableGrid"\n' +
    '               style="margin-right:auto; margin-left:auto; margin-bottom:0pt; border:0.75pt solid #000000; -aw-border:0.5pt single; -aw-border-insideh:0.5pt single #000000; -aw-border-insidev:0.5pt single #000000; border-collapse:collapse">\n' +
    "            <tr>\n" +
    '                <td style="width:26.3pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single">\n' +
    '                    <p style="margin-bottom:0pt; font-size:10pt"><span\n' +
    "                            style=\"font-family:'Times New Roman'\">№ п/п</span></p></td>\n" +
    '                <td style="width:320.4pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single">\n' +
    '                    <p style="margin-bottom:0pt; text-align:center; font-size:10pt"><span\n' +
    "                            style=\"font-family:'Times New Roman'\">Фамилия, имя, отчество</span></p></td>\n" +
    "            </tr>\n" +
    UsersForOne(data?.students) +
    "        </table>\n" +
    "    </div>\n" +
    '    <p style="margin-bottom:0pt; line-height:normal; font-size:8pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '    <table cellspacing="0" cellpadding="0" class="TableGrid"\n' +
    '           style="width:516.65pt; margin-bottom:0pt; border-collapse:collapse">\n' +
    "        <tr>\n" +
    '            <td colspan="3" style="width:109.9pt; vertical-align:top"><p style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; font-weight:bold\">Председатель ИАК:</span></p></td>\n" +
    '            <td style="width:318.95pt; vertical-align:top"><p style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; text-decoration:underline\">" +
    data?.namePredsedatel +
    "</span></p></td>\n" +
    '            <td style="width:87.8pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; border-bottom-style:solid; border-bottom-width:0.75pt; padding-bottom:1pt">\n' +
    "                <span style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:8pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">(подпись)</span></p></td>\n" +
    "        </tr>\n" +
    mapName_Chel_comPodpis(data?.commissionStaff) +
    "        <tr>\n" +
    '            <td style="width:63.8pt; vertical-align:top"><p style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; font-weight:bold\">Секретарь:</span></p></td>\n" +
    '            <td colspan="3" style="width:365.05pt; vertical-align:top"><p style="margin-bottom:0pt"><span\n' +
    "                    style=\"font-family:'Times New Roman'; text-decoration:underline\">" +
    data?.nameSecretar +
    "</span></p></td>\n" +
    '            <td style="width:87.8pt; vertical-align:top"><p\n' +
    '                    style="margin-bottom:0pt; text-align:center; border-bottom-style:solid; border-bottom-width:0.75pt; padding-bottom:1pt">\n' +
    "                <span style=\"font-family:'Times New Roman'; -aw-import:ignore\">&#xa0;</span></p>\n" +
    '                <p style="margin-bottom:0pt; text-align:center; font-size:8pt"><span\n' +
    "                        style=\"font-family:'Times New Roman'\">(подпись)</span></p></td>\n" +
    "        </tr>\n" +
    '        <tr style="height:0pt">\n' +
    '            <td style="width:63.8pt"></td>\n' +
    '            <td style="width:35.45pt"></td>\n' +
    '            <td style="width:10.65pt"></td>\n' +
    '            <td style="width:318.95pt"></td>\n' +
    '            <td style="width:87.8pt"></td>\n' +
    "        </tr>\n" +
    "    </table>\n" +
    '    <p style="margin-bottom:0pt; line-height:normal; font-size:14pt"><span\n' +
    "            style=\"font-family:'Times New Roman'; font-weight:bold; -aw-import:ignore\">&#xa0;</span></p></div>\n" +
    "</body>\n" +
    "</html>"
  );
}
