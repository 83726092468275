let CONSTANTS = {
  GROUP_TAGS: [
    {
      name: "Это магия",
    },
  ],
  STAFF_TYPE: ["Без типа", "Председатель", "Члены комиссии", "Секретарь", "Руководитель"],
  CONTACT_TYPE: [
    {
      label: "Другое",
      disableNumber: true,
    },
    {
      label: "Мобильный номер",
      disableLink: true,
    },
    {
      label: "Мобильный номер + описание",
    },
    {
      label: "Email",
      disableNumber: true,
    },
    {
      label: "Соц. сети",
      disableNumber: true,
    },
    {
      label: "Телеграм",
      disableNumber: true,
    },
    {
      label: "Другое",
    },
    {
      label: "Другое",
    },
  ],
  COURSE_DOCS: [
    {
      docs_id: "1",
      docs_name_full: "Диплом о профессиональной переподготовке + Приложение и обложка",
      center_id: ["16"],
      docs_position: "1",
      docs_is_active: "1",
    },
    {
      docs_id: "2",
      docs_name_full:
        "Свидетельство о профессии рабочего должности служащего + Приложение и обложка",
      center_id: ["16"],
      docs_position: "2",
      docs_is_active: "1",
    },
    {
      docs_id: "3",
      docs_name_full: "Удостоверение о повышении квалификации",
      center_id: ["16"],
      docs_position: "3",
      docs_is_active: "1",
    },
    {
      docs_id: "4",
      docs_name_full: "Диплом установленного образца + Приложение и обложка",
      center_id: ["16"],
      docs_position: "4",
      docs_is_active: "1",
    },
    {
      docs_id: "5",
      docs_name_full: "Свидетельство установленного образца + обложка",
      center_id: ["16"],
      docs_position: "5",
      docs_is_active: "1",
    },
    {
      docs_id: "6",
      docs_name_full:
        "Удостоверение установленного образца младшая медицинская сестра/брат + обложка",
      center_id: ["16"],
      docs_position: "6",
      docs_is_active: "1",
    },
    {
      docs_id: "7",
      docs_name_full: "Удостоверение установленного образца + обложка",
      center_id: ["16"],
      docs_position: "7",
      docs_is_active: "1",
    },
    {
      docs_id: "8",
      docs_name_full: "Сертификат «Серебро»",
      center_id: ["16"],
      docs_position: "8",
      docs_is_active: "1",
    },
    {
      docs_id: "9",
      docs_name_full:
        "Международный сертификат Cualificacion profecional de Estetica y Belleza испанской школы АРМЕНГОЛЬД",
      center_id: ["16"],
      docs_position: "9",
      docs_is_active: "1",
    },
    {
      docs_id: "10",
      docs_name_full: "Международный сертификат от Академии Mesoskinline (Дания)",
      center_id: ["16"],
      docs_position: "10",
      docs_is_active: "1",
    },
  ],
  COURSE_TAGS: [
    {
      name: "ДПЛ и МЕД",
    },
  ],
  COURSE_PRINT_NODE: [
    {
      label: "Удостоверение ППИЭ",
      node: "Udostoverenie PPIE",
      type: 1,
    },
    {
      label: "Сертификат ППИЭ",
      node: "Sertifikat PPIE",
      type: 1,
    },
    {
      label: "Свидетельство, с присвоением квалификации",
      node: "Svidetelstvo, s prisvoeniem kvalifikacii",
      type: 1,
    },
    {
      label: "Свидетельство о профессии",
      node: "Svidetelstvo o professii",
      type: 1,
    },
    {
      label: "Диплом о профессиональной переподготовки по специальности",
      node: "Diplom o professionalnoj perepodgotovki po specialnosti",
      type: 1,
    },
    {
      label: "Свидетельство с присвоением квалификации (+)",
      node: "Svidetelstvo s prisvoeniem kvalifikacii",
      type: 1,
    },
    {
      label: "Удостоверение о повышении квалификации",
      node: "Udostoverenie o povyshenii kvalifikacii",
      type: 1,
    },
    {
      label: "Удостоверение о повышении квалификации+ баллы НМО",
      node: "Udostoverenie o povyshenii kvalifikacii+ bally NMO",
      type: 1,
    },
    {
      label:
        "Два международных сертификата от академии MESOSKINLINE (Дания) BB GLOW THERAPIST и NON-INVASIVE MESOTHERAPY",
      node: "Dva mezhdunarodnyh sertifikata ot akademii MESOSKINLINE (Daniya) BB GLOW THERAPIST i NON-INVASIVE MESOTHERAPY",
      type: 1,
    },
    {
      label: "Протокол (+)",
      node: "Protocol",
      type: 0,
    },
    {
      label: "Итоговая ведомость (+)",
      node: "Itog vedomost",
      type: 0,
    },
    {
      label: "Другое",
      node: "Other",
      type: 1,
    },
  ],
  ORG_NAME_FULL:
    'Общество с ограниченной ответственностью "Первый Профессиональный Институт Эстетики"',
  ORG_NAME_SHORT: 'ООО "Первый Профессиональный Институт Эстетики"',
  URL: {
    API: "api",
    CENTER: "c",
    GET_GROUPS_AND_USERS: "groups",
    GET_COURSE: "courses",
    GET_COURSE_CATEGORY: "catOfCourses",
    GET_DISCIPLINE: "disciplines",
    GET_STAFF: "staff",
    GET_STUDENT: "students",
    GET_DIRECTORYOKSM: "directoryOksm",
    POST_AUTH_LOGIN: "login",
    POST_AUTH_REGISTER: "register",
  },
};
if (process.env.NODE_ENV === "production") {
  CONSTANTS = {
    ...CONSTANTS,
    URL: {
      ...CONSTANTS.URL,
      BASE: "https://docs.karbuh.ru/",
    },
  };
} else if (process.env.NODE_ENV === "development") {
  CONSTANTS = {
    ...CONSTANTS,
    URL: {
      ...CONSTANTS.URL,
      BASE: "http://docandprintapi/",
    },
  };
} else {
  console.log("Ошибка определения констант.");
}
CONSTANTS = Object.freeze(CONSTANTS);
console.log("CONSTANTS - ", CONSTANTS);
export default CONSTANTS;
